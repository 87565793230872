import { KirokuButton }                           from '../../../../ui';
import React                                      from 'react';
import { useTranslation }                         from 'react-i18next';
import { useAsQuestionType, useRecordingContext } from '../../../Recording';
import { useScrollContext }                       from '../../../../ui/Scrolling';
import findIndex                                  from 'lodash/findIndex';

export function getNext({ nextQuestion, index, isAnswers, answers }) {

    if (!isAnswers) {
        isAnswers = !!answers;
    }

    if (typeof nextQuestion === 'number' && isAnswers) {
        return nextQuestion;
    }
    return index + 1;
}

export function getNextMultiChoice({ nextQuestion, index, isAnswers, answers }) {

    if (!isAnswers) {
        isAnswers = findIndex([...answers], (answer) => answer[ 'is_choice' ]) !== -1;
    }

    if (typeof nextQuestion === 'number' && isAnswers) {
        return nextQuestion;
    }
    return index + 1;
}

export function getNextSingleChoice({ answers, index, isAnswers }) {

    if (!isAnswers) {
        isAnswers = !!answers.answer;
    }

    if (typeof answers.nextQuestion === 'number' && isAnswers) {
        return answers.nextQuestion;
    }
    return index + 1;
}


export function getNextQuestionMedia({ answers, index, isAnswers, nextQuestion }) {

    if (!isAnswers) {
        isAnswers = !!answers.answer;
    }

    if (typeof nextQuestion === 'number' && isAnswers) {
        return nextQuestion;
    }

    return index + 1;
}

export function getNextYesNo({ answers, index, isAnswers, nextQuestion, nextQuestionNo, nextQuestionYes }) {

    if (!isAnswers) {
        isAnswers = !!answers;
    }

    if (typeof nextQuestion === 'number' && isAnswers) {
        return nextQuestion;
    }

    if (answers === 'No' && typeof nextQuestionYes === 'number') {
        return nextQuestionNo;
    }

    if (answers === 'Yes' && typeof nextQuestionYes === 'number') {
        return nextQuestionYes;
    }

    return index + 1;

}

export function ButtonNext({ question, onClick = null }) {
    const { t }                                             = useTranslation();
    const { availableQuestions, currentQuestion, isEnding } = useRecordingContext();
    const { scrollTo }                                      = useScrollContext();
    const { navigate }                                      = useAsQuestionType(question);

    const handleNext = () => {
        const functionGetNext   = availableQuestions[ question.type ].getNext;
        const indexNextQuestion = functionGetNext({ ...question, isAnswers: !!question.answers });
        scrollTo(`question-${indexNextQuestion}`, 300);
        navigate(indexNextQuestion);
        if (onClick) {
            onClick();
        }
    };

    return <div hidden={isEnding || question.isDisabled || !(currentQuestion && (question.index === currentQuestion.index))}
    >
        <KirokuButton className="btn-kiroku-primary"
                      onClick={() => handleNext()}>
            {t('common.nextToQuestion')}
        </KirokuButton>
    </div>;
}
