import findIndex from 'lodash/findIndex';
import concat    from 'lodash/concat';
import uniqBy    from 'lodash/uniqBy';

export const SET_USERS                       = 'SET_USERS';
export const HANDLE_CHECK_ALL                = 'HANDLE_CHECK_ALL';
export const HANDLE_CHECK_USER               = 'HANDLE_CHECK_USER';
export const HANDLE_SEARCH_TEAM              = 'HANDLE_SEARCH_TEAM';
export const HANDLE_SEARCH_KEYWORDS          = 'HANDLE_SEARCH_KEYWORDS';
export const CHANGE_UPDATER_SELECTED         = 'CHANGE_UPDATER_SELECTED';
export const HANDLE_CHANGE_TAB_OPTIONS       = 'HANDLE_CHANGE_TAB_OPTIONS';
export const HANDLE_CHECK_ALL_SELECTED       = 'HANDLE_CHECK_ALL_SELECTED';
export const HANDLE_SEARCH_TEAM_CHECKED      = 'HANDLE_SEARCH_TEAM_CHECKED';
export const HANDLE_CHECK_USER_SELECTED      = 'HANDLE_CHECK_USER_SELECTED';
export const HANDLE_SEARCH_KEYWORDS_SELECTED = 'HANDLE_SEARCH_KEYWORDS_SELECTED';
export const HANDLE_RESET_STATE              = 'HANDLE_RESET_STATE';
export const HANDLE_SET_SELECT_TAB           = 'HANDLE_SET_SELECT_TAB';

const setCheckedUsers = (users, isChecked) => {
    return users.map(user => ({ ...user, isChecked: isChecked }));
};

const userInTeam = (teamSelected, users) => {
    if (!teamSelected.length) {
        return users.map(user => ({ ...user, isChecked: false }));
    }
    let listUserInTeam = [];
    teamSelected.forEach(team => {
        listUserInTeam = concat(listUserInTeam, team.users);
    });
    return uniqBy([...listUserInTeam], 'id').map(item => ({ ...item, isChecked: false }));
};

const filterUserUseByKeyword = (user, keywords) => {
    return user.toUpperCase().search(keywords.toUpperCase()) !== -1;
};

function setSelectedForUsers(users, isChecked) {
    return users.map(user => ({ ...user, isChecked: isChecked }));
}

const getUserChecked = (usersChecked, users) => {
    return users.map(user => {
        return {
            ...user,
            isChecked: findIndex(usersChecked, ['id', user.id]) !== -1,
        };
    });
};


export default function (state, action) {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users          : action.users,
                userList       : action.users,
                userCheck      : action.users.filter(user => user.isChecked),
                userListChecked: action.users.filter(user => user.isChecked),
            };
        case CHANGE_UPDATER_SELECTED:
            return {
                ...state,
                userSelected: action.userSelected,
            };
        case HANDLE_CHECK_ALL:
            let isChecked    = findIndex(state.users, user => !user.isChecked) !== -1;
            let checkAllUser = setCheckedUsers(state.users, isChecked);
            let getUserList  = [...state.userList];
            checkAllUser.forEach(user => {
                let indexUser          = findIndex(getUserList, ['id', user.id]);
                getUserList[indexUser] = {
                    ...getUserList[indexUser],
                    isChecked: user.isChecked,
                };
            });
            return {
                ...state,
                users          : checkAllUser,
                userList       : getUserList,
                userCheck      : checkAllUser,
                userListChecked: getUserList,
            };
        case HANDLE_CHECK_USER:
            let rawUsers                   = [...state.users];
            rawUsers[action.index]         = {
                ...rawUsers[action.index],
                isChecked: !rawUsers[action.index].isChecked,
            };
            let rawUserList                = [...state.userList];
            const indexUserCheckBox        = findIndex(rawUserList, (item) => item.id === rawUsers[action.index].id);
            rawUserList[indexUserCheckBox] = {
                ...rawUserList[indexUserCheckBox],
                isChecked: !rawUserList[indexUserCheckBox].isChecked,
            };
            return {
                ...state,
                users          : rawUsers,
                userList       : rawUserList,
                userCheck      : rawUsers.filter(user => user.isChecked),
                userListChecked: rawUsers.filter(user => user.isChecked),
            };
        case HANDLE_SEARCH_KEYWORDS :
            let keywordSearch       = action.keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let searchUserByKeyword = [...state.userList].filter(user => {
                if (state.teamSelected.length) {
                    let userRaw = userInTeam(state.teamSelected, state.userList);
                    return filterUserUseByKeyword(user.name, keywordSearch)
                        && findIndex(userRaw, { id: user.id }) !== -1;
                }
                return filterUserUseByKeyword(user.name, keywordSearch);
            });
            return {
                ...state,
                keywords: action.keywords,
                users   : searchUserByKeyword,
            };
        case HANDLE_SEARCH_TEAM :
            let makeUsers = userInTeam(action.teamSelected, state.userList);
            makeUsers     = [...state.userList].filter(user => {
                    if (state.keywords) {
                        return filterUserUseByKeyword(user.name, state.keywords)
                            && findIndex(makeUsers, { id: user.id }) !== -1;
                    }
                    return findIndex(makeUsers, { id: user.id }) !== -1;
                },
            );
            return {
                ...state,
                teamSelected: action.teamSelected,
                users       : makeUsers,
            };
        case HANDLE_CHANGE_TAB_OPTIONS:
            let newUserList = state.userList.map(user => ({ ...user, user: user.isChecked }));

            let hasCheckedUser = state.userList
                .filter(user => user.isChecked)
                .map(usr => ({ ...usr, isChecked: true }));

            return {
                ...state,
                isSelectedTab  : action.isSelectedTab,
                users          : newUserList,
                userList       : newUserList,
                userCheck      : hasCheckedUser,
                userListChecked: hasCheckedUser,
                teamSelected   : [],
                keywords       : '',
                keywordSelected: '',
            };

        // checked Updater Tab

        case HANDLE_SEARCH_TEAM_CHECKED:
            let isUserChecked = userInTeam(action.teamSelected, state.userListChecked).filter(user => {
                return findIndex(state.userListChecked, { id: user.id }) !== -1;
            });

            isUserChecked = [...state.userListChecked].filter(user => {
                if (state.keywordSelected) {
                    return filterUserUseByKeyword(user.name, action.keywordSelected)
                        && findIndex(isUserChecked, { id: user.id }) !== -1;
                }
                return findIndex(isUserChecked, { id: user.id }) !== -1;
            });

            return {
                ...state,
                teamSelected: action.teamSelected,
                userCheck   : isUserChecked,
            };
        case HANDLE_CHECK_USER_SELECTED:
            let hasCheckUser                = [...state.userCheck];
            hasCheckUser[action.index]      = {
                ...hasCheckUser[action.index],
                isChecked: !hasCheckUser[action.index].isChecked,
            };
            let usersChecked                = [...state.userListChecked];
            let indexUserSelected           = findIndex(usersChecked, (user) => user.id === hasCheckUser[action.index].id);
            usersChecked[indexUserSelected] = {
                ...usersChecked[indexUserSelected],
                isChecked: !usersChecked[indexUserSelected].isChecked,
            };
            return {
                ...state,
                userCheck      : hasCheckUser,
                userListChecked: usersChecked,
                users          : getUserChecked(usersChecked.filter(user => user.isChecked), state.userList),
                userList       : getUserChecked(usersChecked.filter(user => user.isChecked), state.userList),
            };
        case HANDLE_CHECK_ALL_SELECTED:
            let checkUserHasSelected = findIndex(state.userCheck, (user => !user.isChecked)) !== -1;
            let getUserHasSelected   = setSelectedForUsers(state.userCheck, checkUserHasSelected);
            let getUserListChecked   = [...state.userListChecked];

            getUserHasSelected.forEach(user => {
                let userIndex                 = findIndex(getUserListChecked, ['id', user.id]);
                getUserListChecked[userIndex] = {
                    ...getUserListChecked[userIndex],
                    isChecked: user.isChecked,
                };
            });
            let initUserList = [...state.userList];

            getUserHasSelected.forEach(user => {
                let indexUserList           = findIndex(initUserList, usr => user.id === usr.id);
                initUserList[indexUserList] = {
                    ...initUserList[indexUserList],
                    isChecked: user.isChecked,
                };
            });
            return {
                ...state,
                userCheck      : getUserHasSelected,
                userListChecked: getUserListChecked,
                userList       : initUserList,
                users          : initUserList,
            };
        case HANDLE_SEARCH_KEYWORDS_SELECTED:
            let keywordSelected = action.keywordSelected.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let userHasChecked  = [...state.userListChecked].filter(user => {
                if (state.teamSelected.length) {
                    let rawUsers = userInTeam(state.teamSelected, state.userListChecked);
                    return (filterUserUseByKeyword(user.name, keywordSelected)
                        && findIndex(rawUsers, { id: user.id }) !== -1);
                }
                return filterUserUseByKeyword(user.name, keywordSelected);
            });
            return {
                ...state,
                keywordSelected: action.keywordSelected,
                userCheck      : userHasChecked,
            };
        case HANDLE_SET_SELECT_TAB:
            const userCheck = state.users.filter(u => u.isChecked).length;
            return {
                ...state,
                isSelectedTab: userCheck ? 'selectedTab' : 'optionTab',
            };
        case HANDLE_RESET_STATE :
            return {
                ...state,
                keywords       : '',
                keywordSelected: '',
                teamSelected   : []
            }
        default:
            return state;
    }
}
