import React, { useEffect, useState }   from 'react';
import { KirokuCardWeb, KirokuLoading } from '../../../../ui';
import { resources }                    from '@kiroku/api';
import If                               from '../../../If';
import uniqBy                           from 'lodash/uniqBy';
import config                           from '../../../../config';
import LazyLoadAvatarOfHistory          from './LazyLoadAvatarOfHistory';

export function HistoryTab({ customer, show }) {
    const {
              result, search, loading,
              pagination,
          } = resources.useSearch('History', { page: 1, perPage: 15 });

    const [currentPage, setCurrentPage] = useState(1);
    const [hasNext, setHasNext]         = useState(false);
    const [history, setHistory]         = useState([]);

    useEffect(() => {
        if (show) {
            search({
                type    : 'condition',
                customer: customer.id,
            }).then(res => {
                setHasNext(res.hasNext);
            });
            setCurrentPage(1);
        }
    }, [customer.id, search, show]);

    useEffect(() => {
        if (currentPage === 1) {
            setHistory(result);
        }
    }, [result, history, currentPage]);

    function isScrolling() {
        if (window.innerWidth < config.configWithMobile) {
            if ((Math.ceil(document.documentElement.scrollTop) >= document.documentElement.offsetHeight - (window.innerHeight + 100)
                && hasNext && !loading)) {
                search({
                    type    : 'condition',
                    customer: customer.id,
                    page    : pagination.currentPage + 1,
                }).then(res => {
                    setHasNext(res.hasNext);
                    setHistory(uniqBy(history.concat(res.result), 'create_at'));
                });
                setCurrentPage(pagination.currentPage + 1);
            }

        } else {
            if (window.innerHeight + Math.ceil(document.documentElement.scrollTop) + 10 >= document.documentElement.offsetHeight) {
                if (hasNext && !loading) {
                    search({
                        type    : 'condition',
                        customer: customer.id,
                        page    : pagination.currentPage + 1,
                    }).then(res => {
                        setHasNext(res.hasNext);
                        setHistory(uniqBy(history.concat(res.result), 'create_at'));
                    });
                    setCurrentPage(pagination.currentPage + 1);
                }
            }
        }
    }

    useEffect(() => {
        if (show) {
            window.addEventListener('scroll', isScrolling);
            return () => window.removeEventListener('scroll', isScrolling);
        }
    });

    return <div hidden={!show}>
        <KirokuCardWeb className="card card-kiroku-web p-3">

            <If statement={history}>
                {() => history.map((historyItem, index) => {
                    return <div key={index}>
                        <LazyLoadAvatarOfHistory history={ history} historyItem={historyItem} index={index} customer={customer}/>
                        <hr hidden={history.length - 1 === index}/>
                    </div>;
                })}
            </If>
            <div hidden={!loading}>
                <hr/>
                {loading ? <KirokuLoading/> : null}
            </div>
        </KirokuCardWeb>
    </div>;
}
