import React                        from 'react';
import If                           from '../If';
import {
    ComponentMobile,
    ComponentWeb,
    KirokuDateMonthSelect,
    KirokuPagination }              from '../../ui';
import usePageTitle                 from '../../ui/v3/hooks/usePageTitle';
import { useTranslation }           from 'react-i18next';
import MonitoringTerm               from './MonitoringTerm/MonitoringTerm';
import PlanTab                      from './PlanTab/PlanTab';
import TabComponent                 from './TabComponent/TabComponent';
import ReportMonth                  from './ReportMonth/ReportMonth';
import TabComponentMobile           from './TabComponent/TabComponentMobile';
import CertificationExpiration      from './CertificationTab/CertificationExpiration';
import ServiceMonthComponent        from './ServiceEndDate/ServiceMonthComponent';
import './TabComponent/TabComponent.css';
import './monitoringTerm.css';

import ServiceEndDateGroupCustomer from './ServiceEndDate/ServiceEndDateGroupCustomer';
import useMonitoringTermSearch     from './useMonitoringTermSearch';

export default function Customers() {
    const {
              countResult, isGroupCustomerService,
              setIsGroupService, loadingAPI,

              resultServiceEndDate, resultCustomerServiceEndDate,
              resultSearchExpired, result, resultPlan, resultReportMonth,

              pagination,

              setMonthSelectionRange, monthSelectionRange,
              setCondition, condition,
          }     = useMonitoringTermSearch();
    const { t } = useTranslation();
    usePageTitle(t('Layout.CustomersMonitoring'));

    const isHiddenOptionsSelectCustomersMonthly = () => {
        if (condition.type === 'services_end_date') {
            return !resultServiceEndDate.length;
        }
        if (condition.type === 'customer_services_end_date') {
            return !resultCustomerServiceEndDate.length;
        }
        return true;
    };

    return (<div>
        <div className={ 'padding-responsive' }>
            <p style={ { fontWeight: 600, fontSize: 16 } }>{ t('CustomerEditor.monitoringTerms') }</p>
            <ComponentWeb>
                <div className={ 'pb-3' }>
                    <KirokuDateMonthSelect
                        monthSelectedValue={ monthSelectionRange.startMonth }
                        onSelectMonth={ (momentSelected) => {
                            setMonthSelectionRange({
                                startMonth: momentSelected.startOf('month').startOf('day').unix(),
                                endMonth  : momentSelected.endOf('month').endOf('day').unix(),
                            });
                            setCondition({
                                ...condition,
                                page                      : 1,
                                actionSearch              : 'getMonitorCount',
                                monitor_term              : momentSelected.startOf('month').startOf('day').unix(),
                                services_end_date         : momentSelected.startOf('month').startOf('day').unix(),
                                customer_services_end_date: momentSelected.startOf('month').startOf('day').unix(),
                                startMonth                : momentSelected.startOf('month').startOf('day').unix(),
                                endMonth                  : momentSelected.endOf('month').endOf('day').unix(),
                                report_month              : momentSelected.startOf('month').startOf('day').unix(),
                                certification_expiration  : momentSelected.startOf('month').startOf('day').unix(),
                            });
                        } }
                    />
                </div>
                <div>
                    <TabComponent
                        count={ {
                            countCustomer      : countResult?.monitoringTerm,
                            countServiceEndDate: countResult?.serviceEndMonth,
                            countPlan          : countResult?.planEndMonth,
                            countReport        : countResult?.reportMonth,
                            countExpiration    : countResult?.certificationExpiration,
                        } }
                        isGroupCustomer={ isGroupCustomerService }
                        monthSelectionRange={ monthSelectionRange }
                        tabType={ condition.type }
                        handleSelect={ (conditionAfterChangeTab) => {
                            setCondition({
                                ...condition,
                                ...conditionAfterChangeTab,
                                actionSearch: '',
                            });
                        } }
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div
                        hidden={ isHiddenOptionsSelectCustomersMonthly() }
                    >
                        <div className='filter-log'>
                            <div
                                className={ `action-log-entity-status ${ !isGroupCustomerService ? 'active-tab' : '' }` }
                                onClick={ () => {
                                    setCondition({
                                        ...condition,
                                        type             : 'services_end_date',
                                        page             : 1,
                                        status           : 'active',
                                        services_end_date: monthSelectionRange.startMonth,
                                        actionSearch     : '',
                                    });
                                    setIsGroupService(false);
                                } }
                            >
                                { t('CustomerEditor.Service') }
                            </div>
                            <div
                                className={ `action-log-entity-status ${ isGroupCustomerService ? 'active-tab' : '' }` }
                                onClick={ () => {
                                    setCondition({
                                        ...condition,
                                        type                      : 'customer_services_end_date',
                                        page                      : 1,
                                        customer_services_end_date: monthSelectionRange.startMonth,
                                        actionSearch              : '',
                                    });
                                    setIsGroupService(true);
                                } }
                            >
                                { t('common.customer') }
                            </div>
                        </div>
                    </div>
                    <div/>
                    <If statement={ pagination.totalResult }>
                        { () =>
                            <KirokuPagination
                                disabledPrev={ pagination.currentPage === 1 }
                                disabledNext={ pagination.currentPage === pagination.totalPage }
                                increasePage={ () => {
                                    if(loadingAPI) {
                                        return null
                                    }
                                    setCondition({
                                        ...condition,
                                        actionSearch: '',
                                        page        : pagination.currentPage >= pagination.totalPage ? pagination.currentPage : pagination.currentPage + 1,
                                    });
                                } }
                                decreasePage={ () => {
                                    if(loadingAPI) {
                                        return null
                                    }
                                    setCondition({
                                        ...condition,
                                        actionSearch: '',
                                        page        : pagination.currentPage <= 0 ? pagination.currentPage : pagination.currentPage - 1,
                                    });
                                } }
                                currentPage={ pagination.currentPage }
                                totalPage={ pagination.totalPage }
                            />
                        }
                    </If>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className={ 'pb-3' }>
                    <KirokuDateMonthSelect
                        monthSelectedValue={ monthSelectionRange.startMonth }
                        onSelectMonth={ (momentSelected) => {
                            setMonthSelectionRange({
                                startMonth: momentSelected.startOf('month').startOf('day').unix(),
                                endMonth  : momentSelected.endOf('month').endOf('day').unix(),
                            });
                            setCondition({
                                ...condition,
                                page                      : 1,
                                actionSearch              : 'getMonitorCount',
                                monitor_term              : momentSelected.startOf('month').startOf('day').unix(),
                                services_end_date         : momentSelected.startOf('month').startOf('day').unix(),
                                customer_services_end_date: momentSelected.startOf('month').startOf('day').unix(),
                                startMonth                : momentSelected.startOf('month').startOf('day').unix(),
                                endMonth                  : momentSelected.endOf('month').endOf('day').unix(),
                                report_month              : momentSelected.startOf('month').startOf('day').unix(),
                                certification_expiration  : momentSelected.startOf('month').startOf('day').unix(),
                            });
                        } }
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={ 'pb-3' }>
                        <TabComponentMobile
                            count={ {
                                countCustomer      : countResult?.monitoringTerm,
                                countServiceEndDate: countResult?.serviceEndMonth,
                                countPlan          : countResult?.planEndMonth,
                                countReport        : countResult?.reportMonth,
                                countExpiration    : countResult?.certificationExpiration,
                            } }
                            monthSelectionRange={ monthSelectionRange }
                            tabType={ condition.type }
                            isGroupCustomer={ isGroupCustomerService }
                            handleSelect={ (conditionAfterChangeTab) => {
                                setCondition({
                                    ...condition,
                                    ...conditionAfterChangeTab,
                                    actionSearch: '',
                                });
                            } }
                        />
                    </div>
                    <div className={ 'pagination justify-content-flex-end' }>
                        <If statement={ pagination.totalResult }>
                            { () => <KirokuPagination
                                isMobile
                                disabledPrev={ pagination.currentPage === 1 }
                                disabledNext={ pagination.currentPage === pagination.totalPage }
                                increasePage={ () => {
                                    if(loadingAPI) {
                                        return null
                                    }
                                    setCondition({
                                        ...condition,
                                        page: pagination.currentPage >= pagination.totalPage ? pagination.currentPage : pagination.currentPage + 1,
                                    });
                                } }
                                decreasePage={ () => {
                                    if(loadingAPI) {
                                        return null
                                    }
                                    setCondition({
                                        ...condition,
                                        page: pagination.currentPage <= 0 ? pagination.currentPage : pagination.currentPage - 1,
                                    });
                                } }
                                currentPage={ pagination.currentPage }
                                totalPage={ pagination.totalPage }
                            /> }
                        </If>
                    </div>
                </div>
                <div hidden={ isHiddenOptionsSelectCustomersMonthly() }>
                    <div className='filter-log'>
                        <div className={ `action-log-entity-status ${ !isGroupCustomerService ? 'active-tab' : '' }` }
                             onClick={ () => {
                                 setCondition({
                                     ...condition,
                                     type             : 'services_end_date',
                                     page             : 1,
                                     services_end_date: monthSelectionRange.startMonth,
                                     actionSearch     : '',
                                 });
                                 setIsGroupService(false);
                             } }
                        >
                            { t('CustomerEditor.Service') }
                        </div>
                        <div className={ `action-log-entity-status ${ isGroupCustomerService ? 'active-tab' : '' }` }
                             onClick={ () => {
                                 setCondition({
                                     ...condition,
                                     type                      : 'customer_services_end_date',
                                     page                      : 1,
                                     customer_services_end_date: monthSelectionRange.startMonth,
                                     actionSearch              : '',
                                 });
                                 setIsGroupService(true);
                             } }
                        >
                            { t('common.customer') }
                        </div>
                    </div>
                </div>
            </ComponentMobile>

            <MonitoringTerm condition={ condition }
                            result={ result }
                            loadingAPI={ loadingAPI }
                            setCondition={ setCondition }
            />

            <ServiceEndDateGroupCustomer
                condition={ condition }
                isGroupCustomer={ isGroupCustomerService }
                result={ resultCustomerServiceEndDate }
                loadingAPI={ loadingAPI }
            />

            <ServiceMonthComponent
                condition={ condition }
                isGroupCustomer={ isGroupCustomerService }
                result={ resultServiceEndDate }
                loadingAPI={ loadingAPI }
                setCondition={ setCondition }
            />

            <PlanTab condition={ condition }
                     result={ resultPlan }
                     loadingAPI={ loadingAPI }
                     setCondition={ setCondition }
            />

            <ReportMonth
                condition={ condition }
                result={ resultReportMonth }
                loadingAPI={ loadingAPI }
                setCondition={ setCondition }
            />

            <CertificationExpiration
                condition={ condition }
                result={ resultSearchExpired }
                loadingAPI={ loadingAPI }
                setCondition={ setCondition }
            />

            <div className="d-flex justify-content-between pt-3 margin-bottom">
                <div/>
                <If statement={ pagination.totalResult }>
                    { () => <KirokuPagination
                        disabledPrev={ pagination.currentPage === 1 }
                        disabledNext={ pagination.currentPage === pagination.totalPage }
                        increasePage={ () => {
                            if(loadingAPI) {
                                return null
                            }
                            setCondition({
                                ...condition,
                                actionSearch: '',
                                page        : pagination.currentPage >= pagination.totalPage ? pagination.currentPage : pagination.currentPage + 1,
                            });
                        } }
                        decreasePage={ () => {
                            if(loadingAPI) {
                                return null
                            }
                            setCondition({
                                ...condition,
                                actionSearch: '',
                                page        : pagination.currentPage <= 0 ? pagination.currentPage : pagination.currentPage - 1,
                            });
                        } }
                        currentPage={ pagination.currentPage }
                        totalPage={ pagination.totalPage }
                    /> }
                </If>
            </div>
        </div>
    </div>);
}
