import groupBy              from 'lodash/groupBy';
import moment               from 'moment';
import { useTranslation }   from 'react-i18next';
import { getFlowQuestions } from '../HelperComponent/getQuestions';

export default function useTimeChart(records, question) {

    const { t } = useTranslation();

    records = records.map(record => getFlowQuestions(record.survey_template));
    let questions = records.reduceRight((questions, questionsNext) => questions.concat(questionsNext));
    questions = questions.filter(q => q.content === question.content && q.type === question.type && q.type === 'Time');
    questions                  = questions.map(question => {
        return {
            ...question,
            momentAnswers: moment.unix(question.answers).format('HH'),
        };
    });

    const newQuestions = questions.filter(question => isValid(question));
    const questionsGroupByHour = groupBy(newQuestions, (question) => question.momentAnswers);
    const valueAnswers         = Object.keys(questionsGroupByHour).sort().map(key => {
        return [parseInt(key) + t('common.hour'), questionsGroupByHour[ key ].length];
    });
    return {
        data: [['TitleHour', 'H'], ...valueAnswers],
        isNotDataAnalysis: questions.filter(question => !isValid(question)).length === questions.length
    };

}

function isValid(question){
    return question.momentAnswers !== 'Invalid date' && !!question.answers
        && typeof question.answers === 'number' && question.answers !== 0 && question.answers !== ''
}
