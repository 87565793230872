import React                                 from 'react';
import { KirokuCheckboxBorder, KirokuInput } from '../../../../../src/ui';
import findIndex                             from 'lodash/findIndex';
import { useTranslation }                    from 'react-i18next';
import useSelectedCustomer                   from './useSelectedCustomer';
import DropdownSelectTeam                    from '../SelectUpdater/DropdownSelectTeam';

export default function CustomerSelectedTab(props) {
    const { t } = useTranslation();const {
              customersSelected, handleCheckCustomerSelected,
              handleCheckAllSelected, handleChangeKeywordSelected,
              handleChangeTeamSelected, keywordSelected,
              handleResetCondition,
          }     = useSelectedCustomer();
    return (
        <div className="pt-3">
            <div className={ 'pt-2 pb-2 kiroku-section-drop-down-show-more-scroll' }>
                <DropdownSelectTeam handleChangeTags={ (value) => handleChangeTeamSelected(value) }
                                    teamSearch={ props.teams } showModal={props.show}
                                    placeholder={ t('RecordsBeta.refineByTeam') }
                />
            </div>
            <div className={ 'pt-2' }>
                <KirokuInput
                    style={ { height: 36, fontSize: 14 } }
                    placeholder={ t('RecordsBeta.filterByCustomerName') }
                    value={ keywordSelected }
                    onChange={ (e) => handleChangeKeywordSelected(e.target.value) }
                />
            </div>
            <div style={ { padding: '15px 1px 5px 1px' } }/>
            <div>
                { customersSelected.length ?
                    <div>
                        <div className={ `p-3 ${!customersSelected ? 'd-none': 'd-flex justify-content-between align-items-center '}` }
                             style={{ border: 'solid 1px #dfe2e5'}}

                        >
                            <div className={'d-flex align-items-center'}>
                                <label className="check-group">
                                    <input type="checkbox" checked={ findIndex(customersSelected, (ctm) => (!ctm.selected)) === -1 }
                                           onChange={ handleCheckAllSelected }/>
                                    <span className="checkmark"/>
                                </label>
                                <span className={`font-text-content-in-modal pl-3`}>&nbsp;</span>
                            </div>
                            <div onClick={ handleResetCondition }
                                 className={ 'cursor-pointer font-reset-user-changed font-size-16' }
                                 style={ { float: 'right', color: '#007BFF' } }
                            >
                                { t('RecordsBeta.resetSelection') }
                            </div>
                        </div>
                        <div className='background-color' style={ {
                            overflow : 'scroll',
                            height :  'calc(100vh - 500px)'
                        } }>

                            { customersSelected.map((customer, i) => {
                                return (
                                    <div  key={ i } onClick={ () => handleCheckCustomerSelected(i) }
                                    >
                                        <div className={ 'checkbox-bigger' }>
                                            <KirokuCheckboxBorder content={ <span
                                                className={ 'font-text-in-checkbox cursor-pointer' }>{ customer.name }</span> }
                                                                  id={ `${ customer.id }-${ i }` }
                                                                  checked={ !!customer.selected }
                                                                  onChange={ () => handleCheckCustomerSelected(i) }
                                            />
                                        </div>
                                    </div>

                                );
                            }) }
                        </div>
                    </div>:
                    <div className='background-color d-flex'
                         style={ { fontSize: '14px',
                             height: 'calc(100vh - 450px)'
                         } }>
                        <div style={ { margin: 'auto' } }>
                            { t('RecordsBeta.noCustomersSelected') }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
