import findIndex from 'lodash/findIndex';
import remove    from 'lodash/remove';

export const GET_TEMPLATES                         = 'GET_TEMPLATES';
export const CHANGE_ATTACH_STATUS                  = 'CHANGE_ATTACH_STATUS';
export const CHANGE_KEYWORD_TO_SEARCH_ATTACHED     = 'CHANGE_KEYWORD_TO_SEARCH_ATTACHED';
export const CHANGE_KEYWORD_TO_SEARCH_UNATTACHED   = 'CHANGE_KEYWORD_TO_SEARCH_UNATTACHED';
export const HANDLE_CHECK_TEMPLATE_ATTACHED        = 'HANDLE_CHECK_TEMPLATE_ATTACHED';
export const HANDLE_CHECK_TEMPLATE_UNATTACHED      = 'HANDLE_CHECK_TEMPLATE_UNATTACHED';
export const HANDLE_CHECK_ALL_TEMPLATES_ATTACHED   = 'HANDLE_CHECK_ALL_TEMPLATES_ATTACHED';
export const HANDLE_CHECK_ALL_TEMPLATES_UNATTACHED = 'HANDLE_CHECK_ALL_TEMPLATES_UNATTACHED';
export const SET_INIT_ATTACH_TAB_STATUS            = 'SET_INIT_ATTACH_TAB_STATUS';
export const INIT_TEMPLATE_DEFAULT                 = 'INIT_TEMPLATE_DEFAULT';
export const HANDLE_RESET_STATE                    = 'HANDLE_RESET_STATE';

function filterKeywords(templateTitle, keywords) {
    return templateTitle.toLowerCase().search(keywords.toLowerCase()) !== -1;
}

function getTemplateExists(templateDefault, templateUnAttached) {
    if (templateDefault.length) {
        return templateDefault.map(template => {
            remove(templateUnAttached, tmp => template.id === tmp.id);
            return templateUnAttached;
        })[0];
    } else {
        return templateUnAttached;
    }
}

export default function (state, action) {
    switch (action.type) {
        case INIT_TEMPLATE_DEFAULT :
            return {
                ...state,
                templateDefault: action.templateDefault,
            };
        case SET_INIT_ATTACH_TAB_STATUS:
            return {
                ...state,
                attachStatus: action.status,
            };
        case GET_TEMPLATES:
            let templateChecked     = [...state.templateAttachedIds];
            let templateCheckedList = [...state.templateDefault];
            let isCheckedTemplate   = templateCheckedList.map(survey => {
                let checkedIds = findIndex(templateChecked, tmp => tmp.id === survey.id);
                return {
                    ...survey,
                    isChecked: checkedIds !== -1
                };
            });

            if(state.attachStatus === 'unAttach') {
                let templateUnAttachedList = getTemplateExists(state.templateDefault, action.templates);
                let unAttachedIds          = [...state.templateUnAttachedIds];

                let checkUnAttached = templateUnAttachedList.map(survey => {
                    let checkedIds = findIndex(unAttachedIds, tmp => tmp.id === survey.id);
                    return {
                        ...survey,
                        isChecked: checkedIds !== -1,
                    };
                });

                return {
                    ...state,
                    templateUnAttachedList: checkUnAttached,
                    templateUnAttached    : checkUnAttached,
                };
            }

            return {
                ...state,
                templateAttachedList  : isCheckedTemplate,
                templateAttached      : isCheckedTemplate,
            };

        //Change attached status
        case CHANGE_ATTACH_STATUS:
            return {
                ...state,
                attachStatus          : action.attachStatus,
                keywords              : '',
            };

        //Change keyword to search
        case CHANGE_KEYWORD_TO_SEARCH_ATTACHED:
            let keywordSearch = action.keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            const templateRaw = [...state.templateAttachedList].filter(template => {
                return filterKeywords(template.title, keywordSearch);
            });
            return {
                ...state,
                keywords        : action.keywords,
                templateAttached: templateRaw,
            };

        case CHANGE_KEYWORD_TO_SEARCH_UNATTACHED:
            let keywordSearchUnAttach = action.keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            const templateUnAttached  = [...state.templateUnAttachedList].filter(template => {
                return filterKeywords(template.title, keywordSearchUnAttach);
            });
            return {
                ...state,
                keywords          : action.keywords,
                templateUnAttached: templateUnAttached,
            };

        // handle check each template
        case HANDLE_CHECK_TEMPLATE_ATTACHED:
            let makeRawTemplate           = [...state.templateAttached];
            makeRawTemplate[action.index] = {
                ...makeRawTemplate[action.index],
                isChecked: !makeRawTemplate[action.index].isChecked,
            };

            let makeRawTemplateAttachedList = [...state.templateAttachedList];
            const indexTemplate             = findIndex(makeRawTemplateAttachedList, ['id', makeRawTemplate[action.index].id]);

            makeRawTemplateAttachedList[indexTemplate] = {
                ...makeRawTemplateAttachedList[indexTemplate],
                isChecked: !makeRawTemplateAttachedList[indexTemplate].isChecked,
            };
            return {
                ...state,
                templateAttached    : makeRawTemplate,
                templateAttachedList: makeRawTemplateAttachedList,
                templateAttachedIds : makeRawTemplateAttachedList.filter(item => item.isChecked),
            };
        case HANDLE_CHECK_TEMPLATE_UNATTACHED:
            let makeRawTemplateUnAttached           = [...state.templateUnAttached];
            makeRawTemplateUnAttached[action.index] = {
                ...makeRawTemplateUnAttached[action.index],
                isChecked: !makeRawTemplateUnAttached[action.index].isChecked,
            };

            let makeRawTemplateUnAttachedList = [...state.templateUnAttachedList];
            const indexTemplateUnAttachedList = findIndex(makeRawTemplateUnAttachedList, ['id', makeRawTemplateUnAttached[action.index].id]);

            makeRawTemplateUnAttachedList[indexTemplateUnAttachedList] = {
                ...makeRawTemplateUnAttachedList[indexTemplateUnAttachedList],
                isChecked: !makeRawTemplateUnAttachedList[indexTemplateUnAttachedList].isChecked,
            };
            return {
                ...state,
                templateUnAttached    : makeRawTemplateUnAttached,
                templateUnAttachedList: makeRawTemplateUnAttachedList,
                templateUnAttachedIds : makeRawTemplateUnAttachedList.filter(tmp => tmp.isChecked),
            };

        //handle check all template
        case HANDLE_CHECK_ALL_TEMPLATES_ATTACHED:
            const templateAttachNew   = [...state.templateAttached];
            const isCheckedAttach     = findIndex(templateAttachNew, (t) => !t.isChecked) !== -1;
            let checkTemplateAttached = templateAttachNew.map(template => {
                return {
                    ...template,
                    isChecked: isCheckedAttach,
                };
            });

            let templateAttachedList         = [...state.templateAttachedList];
            let checkingTemplateAttachedList = templateAttachedList.map(template => {
                const isIndex = findIndex(checkTemplateAttached, tmp => tmp.id === template.id);
                if (isIndex !== -1) {
                    return {
                        ...template,
                        isChecked: checkTemplateAttached[isIndex].isChecked,
                    };
                }
                return template;
            });

            return {
                ...state,
                templateAttached    : checkTemplateAttached,
                templateAttachedList: checkingTemplateAttachedList,
                templateAttachedIds : checkingTemplateAttachedList.filter(tmp => tmp.isChecked),
            };

        case HANDLE_CHECK_ALL_TEMPLATES_UNATTACHED:
            const templateNew              = [...state.templateUnAttached];
            const isCheckedUnAttached      = findIndex(templateNew, (t) => !t.isChecked) !== -1;
            let checkingTemplateUnAttached = templateNew.map(template => {
                return {
                    ...template,
                    isChecked: isCheckedUnAttached,
                };
            });

            let templateUnAttachListForCheck   = [...state.templateUnAttachedList];
            let checkingTemplateUnAttachedList = templateUnAttachListForCheck.map(template => {
                const isIndex = findIndex(checkingTemplateUnAttached, tmp => tmp.id === template.id);
                if (isIndex !== -1) {
                    return {
                        ...template,
                        isChecked: checkingTemplateUnAttached[isIndex].isChecked,
                    };
                }
                return template;
            });

            return {
                ...state,
                templateUnAttached    : checkingTemplateUnAttached,
                templateUnAttachedList: checkingTemplateUnAttachedList,
                templateUnAttachedIds : checkingTemplateUnAttachedList.filter(tmp => tmp.isChecked),
            };

        //reset modal
        case HANDLE_RESET_STATE:
            return {
                ...state,
                templateUnAttachedIds: [],
                keywords             : '',
                templateAttachedIds  : [],
            };

        default:
            return state;
    }
}
