import React, { useEffect, useState } from 'react';
import {
    KirokuButton, KirokuCheckboxBorder, KirokuInputSearch, KirokuLoader, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader, KirokuNotFound,
}                                     from '../../../ui';
import { useTranslation }             from 'react-i18next';
import { resources }                  from '@kiroku/api';
import uniqBy                         from 'lodash/uniqBy';

export default function ModalSelectMember(props) {
    const { t }                           = useTranslation();
    const { result, search, loading }     = resources.useSearch('User', { type: 'all' });
    const [checkedUsers, setCheckedUsers] = useState(props.checkedUsers || []);
    const [users, setUsers]               = useState([]);
    const [usersBank, setUsersBank]       = useState([]);

    const { show } = props;

    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        search({ type: 'all' });
    }, [search]);

    useEffect(() => {
        if (props.checkedUsers.length ) {
            setCheckedUsers(props.checkedUsers);
        } else {
            setCheckedUsers([]);
        }
    }, [props.checkedUsers])

    useEffect(() => {
        setUsers(result.filter(user => user.role !== 'admin'));
        setUsersBank(result.filter(user => user.role !== 'admin'));
    }, [result]);

    const userIds = users.map(user => user.id);

    const checkedUserIds = checkedUsers.map(user => user.id);

    useEffect(() => {
        if (!show) {
            setUsers(result.filter(user => user.role !== 'admin'));
            setKeyword('');
        }
    }, [result, show]);

    const handleCheckAll   = () => {
        setCheckedUsers([...checkedUsers, ...users]);
    };

    const handleUncheckAll = () => {
        setCheckedUsers(checkedUsers.filter(user => !userIds.includes(user.id)));
    };

    const isCheckedAll = users.reduce((checkedAll, user) => {
        return checkedAll && checkedUserIds.includes(user.id);
    }, true);

    const handleSearch = (keywordSearchName) => {
        let valueSearch = keywordSearchName.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setKeyword(valueSearch);
        setUsers(usersBank.filter(user => {
            return user.name.toLowerCase().search(valueSearch.toLowerCase()) !== -1;
        }));
    };

    return (
        <KirokuModal show={props.show}>
            <KirokuModalHeader>
                <div className="font-header-on-modal">{t('Team.AddMember')}</div>
            </KirokuModalHeader>
            <KirokuModalBody className={'pt-3 pl-3 pr-3'}>
                <div style={ { paddingBottom: 17 } }>
                    <KirokuInputSearch onChange={ (e) => handleSearch(e.target.value) }
                                 value={ keyword }
                                 placeholder={t('Team.placeholderStaffName')}
                                 style={ {height: 36, fontSize: 14}}
                    />
                </div>
                <div id={'body-modal-as'}>
                    {users.length ? <div className={ 'checkbox-bigger p-3 d-flex justify-content-between data-in-modal' }>
                        <div className={'font-size-16'}>{ t('common.Members') }</div>
                        <div className='text-primary' style={ { cursor: 'pointer' } }>
                                        <span hidden={ isCheckedAll }
                                              onClick={ handleCheckAll }
                                              className={'font-size-16'}
                                        >{ t('common.SelectAll') }</span>

                            <span hidden={ !isCheckedAll }
                                  onClick={ handleUncheckAll }
                                  className={'font-size-16'}
                            >{ t('common.UnSelect') }</span>
                        </div>
                    </div>: null }
                    <div className="background-color" style={ {
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 450px)',
                        maxHeight: 'calc(100vh - 450px)',
                        height   : 'auto',
                    } }>
                        <KirokuLoader loading={loading}>
                            <KirokuNotFound isResults={users.length}>
                                {users.map((user, i) => (
                                    <div key={user.id}>
                                        <div className={'checkbox-bigger cursor-pointer'}
                                             onClick={(event) => {
                                                 event.preventDefault();
                                                 if (!checkedUserIds.includes(user.id)) {
                                                     setCheckedUsers([...checkedUsers, user]);
                                                 } else {
                                                     setCheckedUsers(checkedUsers.filter(checkedUser => checkedUser.id !== user.id));
                                                 }
                                             }}
                                        >
                                            <KirokuCheckboxBorder
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setCheckedUsers([...checkedUsers, user]);
                                                    } else {
                                                        setCheckedUsers(checkedUsers.filter(checkedUser => checkedUser.id !== user.id));
                                                    }
                                                }}
                                                id={`${user}-${i}`}
                                                name={'modal-select-member-in-team'}
                                                checked={checkedUserIds.includes(user.id)}
                                                content={<span className={ 'font-text-in-checkbox cursor-pointer' }>{ user.name }</span>}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </KirokuNotFound>
                        </KirokuLoader>
                    </div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    onClick={() => {
                        props.hide();
                    }}
                    color={'white'}
                >
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton onClick={() => {
                    props.hide();
                    props.handleSubmit(uniqBy(checkedUsers, 'id'));

                }} color={'primary'}>
                    {t('common.Apply')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
