import React, { useEffect }                    from 'react';
import SideBar                                 from './SideBar';
import Header                                  from './Header';
import useActionSideBar                        from './useActionSideBar';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import screens                                 from '../Router/screens';
import { KirokuNotification }                  from '../../ui';

export default (props) => {
    const [isOpenMenuSideBar, changeIsOpenMenu] = useActionSideBar();
    let history = useHistory();

    useEffect(() => {
        const ignoreScreenError = ['/404', '/maintenance', '/internal-server-error'];
        localStorage.setItem('currentURL', ignoreScreenError.includes(history.location.pathname) ? '/ ': history.location.pathname.concat(history.location.search));
    }, [history.location]);

    return (
        <div>
            <div id='main'
                 className={isOpenMenuSideBar ? 'opacity-mobile' : ''}>
                <div id='overlay-main' className="overlay"
                     onClick={() => {
                         changeIsOpenMenu();
                     }}/>
                <div className="slider-close"/>
                <KirokuNotification/>
            </div>
            <SideBar/>
            <Header path={props.location.pathname}/>
            <div className={`main-container ${!isOpenMenuSideBar && 'main-collapse'}`}>
                <div className={'main-content'}>
                    <Switch>
                        <Route path={"/"} exact render={() => <Redirect to={localStorage.getItem('currentURL') || "/ "} />} />
                        {
                            screens.map((screen, index) => {
                                return <Route
                                    path={screen.path}
                                    component={screen.component}
                                    exact={screen.exact}
                                    key={index}
                                />;
                            })
                        }
                        <Redirect to="/404"/>
                    </Switch>
                </div>
            </div>
        </div>

    );
}
