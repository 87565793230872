import React, { useEffect, useState } from 'react';
import {
    KirokuButton, KirokuCheckboxBorder, KirokuInputSearch, KirokuLoader, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader, KirokuNotFound,
}                                     from '../../../ui';
import uniqBy                         from 'lodash/uniqBy';
import { resources }                  from '@kiroku/api';
import { useTranslation }             from 'react-i18next';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';
import useNotification                from '../../Layout/useNotification';
import DropdownSelectCustomerFromTeam from './DropdownSelectCustomerFromTeam';

export default function ModalSelectCustomerAssign(props) {
    const { t }                                   = useTranslation();
    const {
              search, cancelAPISearch,
          }                                       = resources.useSearch('Customer');
    const [keywordSearch, setKeywordSearch]       = useState('');
    const [customers, setCustomers]               = useState([]);
    const [checkedCustomers, setCheckedCustomers] = useState([]);
    const assignments                             = resources.useAssign('Assignment')[0];
    const [loadingAPI, setLoadingAPI]             = useState(false);
    const [teamSelecting, setTeamSelecting]       = useState([]);
    const [initCustomers, setInitCustomers]       = useState([]);
    const { notification }                        = useNotification();

    const { show, templates, onCloseModal } = props;

    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        if (show) {
            setLoadingAPI(true);
            search({ type: 'all' }).then(res => {
                setLoadingAPI(false);
                setCustomers(res.result);
                setInitCustomers(res.result);
            });
        }

        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch, show]);

    useEffect(() => {
        if (!teamSelecting.length) {
            setCustomers(initCustomers);
        }
    }, [teamSelecting, initCustomers]);

    const checkedCustomerIds = checkedCustomers.map(customer => customer.id);
    const customerIds        = customers.map(customer => customer.id);

    const handleCheckAll = () => {
        setCheckedCustomers([...checkedCustomers, ...customers]);
    };

    const handleUncheckAll = () => {
        setCheckedCustomers(checkedCustomers.filter(customer => !customerIds.includes(customer.id)));
    };

    let isCheckAll = customers.reduce((checkAll, customer) => {
        return checkAll && checkedCustomerIds.includes(customer.id);
    }, true);

    const handleConfirmAssign = () => {
        let checkedSurveyIds   = templates.map(survey => survey.id);
        let checkedCustomerIds = uniqBy(checkedCustomers, 'id').map(customer => customer.id);
        let assignValues       = {
            customers: checkedCustomerIds,
            surveys  : checkedSurveyIds,
        };
        assignments(assignValues).then(() => {
            notification(t('message.AssignSuccess'), 'success');
            handleResetCondition();
            onCloseModal();
        }).catch(error => {
            console.log(error);
        });
        trackingMixpanel('ASSIGN_SURVEY_TO_CUSTOMER', assignValues);
    };

    const handleChangeSearchKeyword = (keywords) => {
        let keywordReg = keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setCustomers(initCustomers.filter((ctm => ctm.name.toLowerCase().search(keywordReg.toLowerCase()) !== -1)))
    };

    const handleResetCondition = () => {
        setCheckedCustomers([]);
        setKeywordSearch('');
    }

    return (
        <KirokuModal show={ show }>
            <KirokuModalHeader>
                <div className="font-size-18"> { t('createSurvey.AssignSurveyTemplateToCustomer') }</div>
            </KirokuModalHeader>
            <KirokuModalBody className={'pt-3 pl-3 pr-3'}>
                <div>
                    <KirokuInputSearch onChange={ (event) => {
                        setKeywordSearch(event.target.value);
                        handleChangeSearchKeyword(event.target.value);
                    } }
                       style={ {height: 36, fontSize: 14}}
                       value={ keywordSearch }
                       id={ 'input-search-condition' }
                       placeholder={ t('createSurvey.EnterCustomerNameOrTeamName') }
                    />
                </div>
                <div className={ 'pt-3 pb-3' }>
                    <DropdownSelectCustomerFromTeam
                        show={ show }
                        getCustomers={ customers => {
                            if (customers.length) {
                                setCustomers(customers);
                            }
                        } }
                        getTeamSelecting={ setTeamSelecting }
                    />
                </div>

                <div id="body-modal-as">
                    <div hidden={!customers.length}>
                        <div className={ 'checkbox-bigger p-3 d-flex justify-content-between' }
                             style={{ border: 'solid 1px #dfe2e5'}}>
                            <div className={'font-size-16'}>{ t('common.Name') }</div>
                            <div className='text-primary' style={ { cursor: 'pointer' } }>
                                <span hidden={ isCheckAll || !customers.length }
                                      className={'font-size-16'}
                                      onClick={ handleCheckAll }
                                >{ t('common.SelectAll') }</span>
                                <span hidden={ !isCheckAll || !customers.length }
                                      className={'font-size-16'}
                                      onClick={ handleUncheckAll }
                                >{ t('common.UnSelect') }</span>
                            </div>
                        </div>
                    </div>
                    <div className="background-color" style={ {
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 450px)',
                        maxHeight: 'calc(100vh - 450px)',
                        height   : 'auto',
                    } }>
                        <KirokuLoader loading={ loadingAPI }>
                            <KirokuNotFound isResults={ customers.length }>
                                { customers.map((customer, i) => {
                                    return <div key={ customer.id }
                                                className={ 'cursor-pointer' }
                                                onClick={ (event) => {
                                                    event.preventDefault();
                                                    if (!checkedCustomerIds.includes(customer.id) ) {
                                                        setCheckedCustomers([...checkedCustomers, customer]);
                                                    } else {
                                                        setCheckedCustomers(checkedCustomers.filter(checkedCustomer => customer.id !== checkedCustomer.id));
                                                    }
                                                } }
                                    >
                                        <div className={ 'checkbox-bigger' }>
                                        <KirokuCheckboxBorder
                                            onChange={ (event) => {
                                                if (event.target.checked) {
                                                    setCheckedCustomers([...checkedCustomers, customer]);
                                                } else {
                                                    setCheckedCustomers(checkedCustomers.filter(checkedCustomer => customer.id !== checkedCustomer.id));
                                                }
                                            } }
                                            id={ `${ customer.id }-${ i }` }
                                            content={ <span
                                                className={ 'font-text-in-checkbox cursor-pointer font-size-14' }>{ customer.name }</span> }
                                            checked={ checkedCustomerIds.includes(customer.id) }
                                        />
                                    </div>
                                </div>;
                                }) }
                            </KirokuNotFound>
                        </KirokuLoader>
                    </div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ () => {
                    onCloseModal();
                    handleResetCondition();
                } }
                              color={'light'}
                              style={ { height: 36}}
                >
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton
                    disabled={ !checkedCustomers.length }
                    onClick={ handleConfirmAssign }
                    style={ { height: 36}}
                    color={'primary'}>
                    { t('plans.confirm') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
