import React, { useEffect, useState } from 'react';
import {
    KirokuButton, KirokuCheckboxBorder, KirokuInputSearch, KirokuModal, KirokuModalBody, KirokuModalFooter,
    KirokuModalHeader,
}                                     from '../../../ui';
import { useTranslation }             from 'react-i18next';
import findIndex                      from 'lodash/findIndex';
import KirokuNotFound                 from '../../../ui/KirokuNotFound';

export default function ModalSelectSurveyAssign(props) {
    const { t }                                                                       = useTranslation();
    const { show, templates, onCloseModal, handleCheckedTemplate, showModalCustomer } = props;
    const [templateBanker, setTemplateBanker]                                         = useState([]);
    const [templateShowing, setTemplateShowing]                                       = useState([]);
    const [keywords, setKeywords]                                                     = useState('');

    useEffect(() => {
        if (show) {
            setTemplateBanker(templates);
            setTemplateShowing(templates);
        }
    }, [show, templates]);

    const setInitialTemplate = () => {
        setTemplateShowing(templates.map(template => {
            return {
                ...template,
                isChecked: false,
            };
        }));
        setTemplateBanker(templates.map(template => {
            return {
                ...template,
                isChecked: false,
            };
        }));
        setKeywords('');
    };

    const handleCheckSurvey = (templateId) => {
        setTemplateShowing(templateShowing.map(template => {
            if (template.id === templateId) {
                return {
                    ...template,
                    isChecked: !template.isChecked,
                };
            }
            return template;
        }));
        setTemplateBanker(templateBanker.map(template => {
            if (template.id === templateId) {
                return {
                    ...template,
                    isChecked: !template.isChecked,
                };
            }
            return template;
        }));

    };

    const handleCheckAll = () => {
        const templateList = templateShowing.map(template => {
            return {
                ...template,
                isChecked: findIndex(templateShowing, tmp => !tmp.isChecked) !== -1,
            };
        });
        setTemplateShowing(templateList);
        setTemplateBanker(templateBanker.map(template => {
            if (findIndex(templateList, tmp => tmp.id === template.id) !== -1) {
                return {
                    ...template,
                    isChecked: !template.isChecked,
                };
            }
            return template;
        }));

    };

    const handleChangeKeywords = (e) => {
        let inputValue = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setTemplateShowing(templateBanker.filter((template) => template.title.toLowerCase().search(inputValue.toLowerCase()) !== -1));
        setKeywords(inputValue);
    };

    const handleConfirm = () => {
        let templateChecked = templateBanker.filter(template => template.isChecked);
        handleCheckedTemplate(templateChecked);
        setKeywords('');
        setInitialTemplate();
        onCloseModal();
        showModalCustomer();
    };

    return (
        <div>
            <KirokuModal show={ show }>
                <KirokuModalHeader>
                    <div className={ 'font-size-18' }>
                        { t('common.PleaseSelect') }
                    </div>
                </KirokuModalHeader>
                <KirokuModalBody className={'pt-3 pl-3 pr-3'}>
                    <div className={ 'mb-3' }>
                        <KirokuInputSearch
                            value={ keywords }
                            onChange={ (e) => {
                                handleChangeKeywords(e);
                            } }
                            placeholder={ t('Surveys.placeholderSearch')}
                            style={ {height: 36, fontSize: 14}}
                        />
                    </div>
                    <div id="body-modal-as">
                        {templateShowing.length ? <div
                            className={ 'checkbox-bigger p-3 border-bottom d-flex justify-content-between' }
                            style={{ border: 'solid 1px #dfe2e5'}}
                        >
                            <div className={'font-size-16'}>{ t('common.Name') }</div>
                            <div className='text-primary'>
                                        <span
                                            hidden={ findIndex(templateShowing, template => !template.isChecked) === -1 || !templateShowing.length }
                                            onClick={ () => handleCheckAll() }
                                            className={'font-size-16'}
                                        >{ t('common.SelectAll') }</span>

                                <span
                                    hidden={ findIndex(templateShowing, template => !template.isChecked) !== -1 || !templateShowing.length }
                                    onClick={ () => handleCheckAll() }
                                    className={'font-size-16'}
                                >{ t('common.UnSelect') }</span>
                            </div>
                        </div>: null}
                        <div className='background-color' style={ {
                            overflow : 'scroll',
                            minHeight: 'calc(100vh - 360px)',
                            maxHeight: 'calc(100vh - 360px)',
                            height   : 'auto',
                        } }>
                                <KirokuNotFound isResults={ templateShowing.length }>
                                    { templateShowing.map((template) => (
                                        <div key={ template.id }>
                                            <div className={'checkbox-bigger'}
                                                 onClick={(event) => {
                                                     event.preventDefault();
                                                     handleCheckSurvey(template.id);
                                                 }}>


                                            <KirokuCheckboxBorder
                                                onChange={ () => handleCheckSurvey(template.id) }
                                                checked={ !!template.isChecked }
                                                content={ <span
                                                    className={ 'font-text-in-checkbox cursor-pointer font-size-14' }>{ template.title }</span> }/>
                                            </div>
                                        </div>
                                    ))}
                                </KirokuNotFound>
                        </div>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => {
                        onCloseModal();
                        setInitialTemplate();
                    } }
                                  style={ { height: 36}}
                                  color={ 'light' }

                    >
                        { t('common.Cancel') }
                    </KirokuButton>
                    <KirokuButton onClick={ handleConfirm }
                                  disabled={ !templateBanker.filter(tmp => tmp.isChecked).length }
                                  color={ 'primary' }
                                  style={ { height: 36}}
                    >
                        { t('plans.confirm') }
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        </div>
    );
}
