import moment               from 'moment';
import findIndex            from 'lodash/findIndex';
import groupBy              from 'lodash/groupBy';
import max                  from 'lodash/max';
import min                  from 'lodash/min';
import { getFlowQuestions } from '../HelperComponent/getQuestions';

function getRange(count) {
    return Array.from({ length: count }, (_, i) => i);
}


function shiftDate(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return moment(newDate).format('YYYY/M/D');
}

export default function useOnlyDateChart(records, question) {
    let flowedQuestions = records.map(record => {
        return {
            ...getFlowQuestions(record.survey_template).filter(item => item.content === question.content && item.type === question.type).map(item => {
                return {
                    ...item,
                    answerQuestions: moment(item.answers * 1000).unix(),
                };
            }),
        };
    }).map(itm => itm[ '0' ]).filter(itm => itm);

    let recordGroupDays                  = groupBy(flowedQuestions, 'answerQuestions');
    const arraySearchRangeAnswerQuestion = flowedQuestions.map(item => item.answerQuestions).filter(question => !isNaN(question));
    const startDate                      = moment.unix(min(arraySearchRangeAnswerQuestion)).subtract(2, 'days');
    const endDate                        = moment.unix(max(arraySearchRangeAnswerQuestion)).add(1, 'days');
    const numberDay                      = endDate.diff(startDate, 'day');
    let resultRangeSearch                = getRange(numberDay).map(index => {
        return {
            date : shiftDate(endDate, -index),
            count: 0,
        };
    });

    Object.keys(recordGroupDays).map(key => {
        const indexResultRecordsAnswersOfResultRangeSearch = findIndex(resultRangeSearch, (item) => item.date === moment.unix(key).format('YYYY/M/D'));
        if (indexResultRecordsAnswersOfResultRangeSearch !== -1) {
            resultRangeSearch[ indexResultRecordsAnswersOfResultRangeSearch ] = {
                ...resultRangeSearch[ indexResultRecordsAnswersOfResultRangeSearch ],
                count: recordGroupDays[ key ].length,
            };
        }
        return {
            date : moment.unix(key).format('YYYY/M/D'),
            count: recordGroupDays[ key ].length,
        };
    });
    const minimumDate = min(flowedQuestions.map(item => item.answers));
    const maxDate     = max(flowedQuestions.map(item => item.answers));
    return {
        data     : resultRangeSearch,
        startDate: minimumDate,
        endDate  : maxDate,
        isNotDataAnalysis: flowedQuestions.filter(question => !question.answerQuestions).length === flowedQuestions.length
    };
}
