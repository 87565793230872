import React, { useState }                                                      from 'react';
import { KirokuCardBodyWeb, KirokuCardWeb, KirokuRadio, KirokusSortableHandle } from '../../../ui';
import ActionGroupBtn                                                           from './ActionGroupBtn';
import QuestionCardTitle                                                        from './QuestionTitleCard';
import InfoQuestion                                                             from './InfoQuestion';
import CheckboxUnrequited                                                       from './CheckboxUnrequited';
import findIndex                                                                from 'lodash/findIndex';
import NextQuestion                                                             from './NextQuestion';
import useQuestions                                                             from '../MakeSurvey/useQuestions';
import { modalSetLogic }                                                        from '../ModalSetLogicByQuestionType';
import ModalEditQuestion
                                                                                from '../FormQuestion/ModalEditQuestion';

export default function Single({ question }) {
    const { update, copy, delQuestion }                         = useQuestions();
    const ModalSetLogic                                         = modalSetLogic[question.type];
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const [isOpenModalName, setIsOpenModalName]                 = useState(null);

    const singleQuestion = question.choices.map(item => {
        return { nextQuestion: item.nextQuestion };
    });

    const isLogicSingle = findIndex(singleQuestion, (o) => {
        return typeof o.nextQuestion === 'number';
    }) !== -1;
    let isLogic         = question.nextQuestion === 0 || question.nextQuestion || isLogicSingle;

    const setDefaultAnswer         = (indexAnswerDefault) => {
        let questionNew     = { ...question };
        questionNew.choices = questionNew.choices.map((choice, index) => {
            return {
                ...choice,
                defaultAnswer: index === indexAnswerDefault,
            };
        });
        update(questionNew);
    };
    const handleResetDefaultAnswer = () => {
        let questionNew     = { ...question };
        questionNew.choices = questionNew.choices.map((choice) => {
            if (choice.defaultAnswer !== undefined) {
                delete choice.defaultAnswer;
            }
            return choice;
        });
        update(questionNew);
    };

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={isLogic}
                        >
                            <ActionGroupBtn
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                handleOpenModalEdit={() => {
                                    setIsOpenModalEditQuestion(true);
                                }}
                                question={question}
                                resetDefaultAnswer={() => handleResetDefaultAnswer()}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>
                    </KirokusSortableHandle>
                    <KirokuCardBodyWeb>
                        <CheckboxUnrequited
                            question={question}
                            isNotUse={isLogic}
                        />
                        <InfoQuestion question={question}/>

                        <div className='answer-question'>
                            {question.choices.map((choice, index) =>
                                <div key={index}>
                                    <KirokuRadio
                                        key={index}
                                        id={`${question.index}-${index}`}
                                        name={`singleChoice_${question.index}`}
                                        checked={!!choice.defaultAnswer}
                                        onChange={() => setDefaultAnswer(index)}
                                    >
                                        {choice.answer}
                                    </KirokuRadio>
                                    <NextQuestion nextQuestion={choice.nextQuestion}/>
                                </div>,
                            )}
                        </div>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />
            </div>
        </div>
    );
}
