import { Link }                          from 'react-router-dom';
import moment                            from 'moment';
import { KirokuCard, KirokuPopoverItem } from '../../../../ui';
import Action                            from '../../../../ui/Action';
import React, { useEffect, useState }    from 'react';
import { useTranslation }                from 'react-i18next';
import findIndex                         from 'lodash/findIndex';

export default function PlanTabMobile(props) {
    const { t }                = useTranslation();
    const { result, surveyId } = props;
    const [plans, setPlans]    = useState(result);
    const now = moment().unix();

    useEffect(() => {
        setPlans(result);
    }, [result]);

    const handleShowItem = (id) => {
        setPlans(plans.map((plan) => {
            return {
                ...plan,
                showCollapse: plan.id === id ? !plan.showCollapse : plan.showCollapse,
            };
        }));
    };

    return plans.map((plan, index) => (
        <div key={ index } className={ 'card border-bottom-dfe2e5' }>
            <div>
                <div className="d-flex justify-content-between">
                    <div onClick={ () => {
                        handleShowItem(plan.id);
                    } }>
                        <div>
                            <div className="d-flex align-items-center "
                                 style={ {
                                     height     : 40,
                                     paddingLeft: 10,
                                     minWidth   : 'max-content',
                                 } }
                            >
                                <div style={ { width: 22, height: 22 } }>
                                    <i className={ `fas fa-chevron-${ plan.showCollapse ? 'down' : 'right' }` }
                                       style={ { color: '#6C798F' } }
                                    />
                                </div>
                                <div className={ ' text-primary overflow-text' }>
                                    <Link to={ `/plans/${ plan.id }` }>
                                        { plan.overview }
                                    </Link>
                                </div>
                            </div>
                            <div style={ { paddingLeft: 22, paddingBottom: 16 } }>
                                <div>
                                    { t('plans.customers') }: &nbsp;{ plan.customer ? plan.customer.name : '' }
                                </div>
                                <div className="pt-1">
                                    { t('plans.startDate') }: &nbsp;
                                    { moment.unix(plan.start_at).format(t('dateTimeFormat.YearMonthDateWithDay')) }
                                </div>
                                <div className="pt-1">
                                    { t('plans.endDate') }: &nbsp;
                                    { moment.unix(plan.end_at).format(t('dateTimeFormat.YearMonthDateWithDay')) }
                                </div>
                                <div className="d-flex pt-1">
                                    <div>
                                        { t('plans.planStatus') } : &nbsp;
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between pl-1">
                                        <div hidden={plan.published_at || plan.review_at}>
                                            <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                                            &nbsp;<span style={{ fontSize: 14 }}>{t('plans.draft')}</span>
                                        </div>
                                        <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                            <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                                            &nbsp;<span style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
                                        </div>
                                        <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at}>
                                            <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                                            &nbsp;<span style={{ fontSize: 14 }}>{t('plans.running')}</span>
                                        </div>
                                        <div hidden={!(plan.published_at && now > plan.end_at)}>
                                            <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                                            &nbsp;<span style={{ fontSize: 14 }}>{t('plans.finish')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center pt-1">
                                    <div>{t('plans.statusReview')} : &nbsp;</div>
                                    <div>
                                        <div hidden={plan.review_status !== 0}> {t('plans.unReview')}</div>
                                        <div hidden={plan.review_status !== 1}> {t('plans.draftReview')}</div>
                                        <div hidden={plan.review_status !== 2}> {t('plans.monitored')}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                        <Action>
                            <KirokuPopoverItem style={{width: 120}}>
                                <div className='fix-icon-align'>
                                    <Link to={ `/plans/${ plan.id }` }>
                                                <span className='ml-1 font-size-14'>
                                                    {t('plans.viewPlan')}
                                                </span>
                                    </Link>
                                </div>
                            </KirokuPopoverItem>
                        </Action>
                    </div>
                </div>

                { plan.showCollapse ? <div className={ 'card' }>
                    <div>
                        { plan.supports.map((support, index) =>
                            <div className={ 'support-info' } key={ index }>
                                <KirokuCard
                                    action={
                                        <div hidden={ findIndex(support.surveys, (survey) => {
                                            return parseInt(survey.id) === parseInt(surveyId);
                                        }) === -1 }>
                                            <Action>
                                                <KirokuPopoverItem style={ { width: 120 } }>
                                                    <div
                                                        className='d-flex justify-content-between p-1 fix-icon-align'>
                                                        <Link
                                                            to={ `/records/create/customer/${ plan.customer.id }/survey/${ surveyId }` }>
                                                            <i className={ 'fas fa-file-signature pr-1' }/>
                                                            <span>{ t('common.Records') }</span>
                                                        </Link>
                                                    </div>
                                                </KirokuPopoverItem>
                                            </Action>
                                        </div> }
                                >
                                    <div>
                                <span
                                    className="ellipse d-flex justify-content-center align-items-center">
                                    { support.priority }
                                </span>
                                        <div>{ support.overview }</div>
                                        <div className={ 'pt-1' }>
                                            <div>{ t('plans.objective') }: &nbsp;{ support.target }</div>
                                        </div>
                                        <div className={ 'pt-1' }>
                                            <div>{ t('plans.achievementTime') }: &nbsp;{ support.term }{ t('plans.monthLater') }</div>
                                        </div>
                                        <div className={ 'd-flex pt-1' }>
                                            <div>{ t('plans.statusReview') }: &nbsp;</div>
                                            {support['review_at'] ?
                                                <div className="font-content-table">
                                                    {support['has_draft_review'] ?
                                                    <div className="d-flex align-items-center">
                                                        <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"
                                                             style={ { width: 18, height: 16 } }
                                                        /> &nbsp;<span
                                                        className={ 'font-size-14' }>{ t('plans.draft') }</span>
                                                    </div> :
                                                    <div>
                                                        <img src={`/assets/img/reviewd.svg`} alt="reviewd.svg"/>
                                                        &nbsp;{t('plans.monitoredStatus')}
                                                    </div> }
                                                </div> : ''
                                            }
                                        </div>
                                    </div>
                                </KirokuCard>
                            </div>) }
                    </div>
                </div> : <div>
                </div> }
            </div>
        </div>
    ));
}
