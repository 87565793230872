import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';

import {
    KirokuButton, KirokuCardList, KirokuCheckboxBorder, KirokuInputSearch, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader,
}                    from '../../../../../src/ui';
import findIndex     from 'lodash/findIndex';
import { resources } from '@kiroku/api';
import KirokuLoader  from '../../../../ui/KirokuLoader';

export default function ModalSelectUserSetRoleStaff(props) {
    const { t }                                    = useTranslation();
    const { show, closeModal, searchAfterSetRole } = props;
    const [userModal, setUsers]                    = useState([]);
    const [usersBank, setUserBanks]                = useState([]);
    const [keywords, setKeywords]                  = useState('');
    const [setRole]                                = resources.useUpdateRole('RoleUser');
    const { search, result, cancelAPISearch }      = resources.useSearch('User');
    const [loadingAPI, setLoading]                 = useState(false);

    useEffect(() => {
        if (show) {
            setLoading(true);
            search({ type: 'all' }).then(() => setLoading(false));
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, show, cancelAPISearch]);

    useEffect(() => {
        if (result.length) {
            setUsers(result);
            setUserBanks(result);
        }
    }, [result]);

    const handleCheckAll = () => {
        const isCheckAll = findIndex(userModal, user => !user.isChecked) === -1;
        setUsers(userModal.map(user => {
            return {
                ...user,
                isChecked: !isCheckAll,
            };

        }));
    };

    const handleCheckUser = (index) => {
        setUsers(userModal.map((user, userIndex) => {
            if (index === userIndex) {
                return {
                    ...user,
                    isChecked: !user.isChecked,
                };
            }
            return user;
        }));
    };

    const handleChangeInput = (keyword) => {
        setUsers(usersBank.filter(user => {
            return user.name.toLowerCase().search(keyword.toLowerCase()) !== -1;
        }));
    };

    const handleConfirmSetRole = () => {
        let userIds      = userModal.filter(item => item.isChecked).map(user => user.id);
        let valueSetRole = {
            users: userIds,
            role : 'staff',
        };
        setRole(valueSetRole).then(() => {
            closeModal();
            searchAfterSetRole();
        });
    };

    return (
        <KirokuModal show={ show }>
            <KirokuModalHeader>
                <h6 className="mb-3 font-size-16">{ t('common.ChoiceUser') }</h6>
                <KirokuInputSearch
                    value={ keywords }
                    onChange={ (event) => {
                        let inputSearch = event.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
                        setKeywords(inputSearch);
                        handleChangeInput(inputSearch);

                    } }/>
            </KirokuModalHeader>
            <KirokuModalBody>
                <div id="body-modal-as">
                    <KirokuCardList
                        headerLeft={ t('common.Name') }
                        headerRight={
                            <div className='text-primary' style={ { cursor: 'pointer' } }>
                                        <span hidden={ findIndex(userModal, user => !user.isChecked) === -1 }
                                              onClick={ handleCheckAll }
                                        >{ t('common.SelectAll') }</span>

                                <span hidden={ findIndex(userModal, user => !user.isChecked) !== -1 }
                                      onClick={ handleCheckAll }
                                >{ t('common.UnSelect') }</span>
                            </div>
                        }
                    >
                        <section className="scroll-data-survey">
                            <KirokuLoader loading={ loadingAPI }>
                                { userModal.map((user, index) => (
                                    <div key={ user.id }>
                                        <KirokuCheckboxBorder
                                            onChange={ () => handleCheckUser(index) }
                                            onClick={ () => handleCheckUser(index) }
                                            checked={ user.isChecked || false }
                                            content={ user.name }/>
                                    </div>
                                )) }
                            </KirokuLoader>
                        </section>
                    </KirokuCardList>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ closeModal } color={ 'white' }>
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton disabled={ !userModal.filter(user => user.isChecked).length }
                              onClick={ handleConfirmSetRole }
                              color={ 'primary' }>
                    { t('common.Apply') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
