import {
    KirokuButton, KirokuCardList, KirokuCheckboxBorder, KirokuInputSearch, KirokuLoader, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader,
}                                     from '../../../ui';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import findIndex                      from 'lodash/findIndex';
import If                             from '../../If';
import { resources }                  from '@kiroku/api';

export default function ModalSelectCustomers(props) {

    const { isShowModal, cancel, confirm }             = props;
    const { result, search, loading, cancelAPISearch } = resources.useSearch('Customer', { type: 'all' });
    const { t }                                        = useTranslation();
    const [customersOnModal, setCustomerOnModal]       = useState([]);
    const [customers, setCustomers]                    = useState([]);
    const [nameKey, setNameKey]                        = useState('');

    useEffect(() => {
        setCustomers(result);
        setCustomerOnModal(result);
    }, [result]);

    useEffect(() => {
        if (isShowModal) {
            search({ type: 'all' });
        }
        return () => {
            cancelAPISearch();
        }
    }, [search, isShowModal, cancel, cancelAPISearch]);

    const handleCheckAll = () => {
        let customersSelected = customersOnModal.map(customer => {
            return {
                ...customer,
                isChecked: findIndex(customersOnModal, (ctm) => !ctm.isChecked) !== -1,
            };
        });
        setCustomerOnModal(customersSelected);
        setCustomers(customers.map(ctm => {
            const indexCustomerSelected = findIndex(customersSelected, (customer) => ctm.id === customer.id);
            if (indexCustomerSelected !== -1) {
                return customersSelected[ indexCustomerSelected ];
            }
            return ctm;
        }));
    };

    const setInitModal = () => {
        setNameKey('');
        setCustomers(customers.map(customer => {
            return {
                ...customer,
                isChecked: false,
            };
        }));
        setCustomerOnModal(customers.map(customer => {
            return {
                ...customer,
                isChecked: false,
            };
        }));
    };

    const handleConfirm = () => {
        confirm(customers.filter(ctm => ctm.isChecked));
        setInitModal();
    };

    const handleCancel = () => {
        cancel();
        setInitModal();
    };

    const handleSearchName = (nameKey) => {
        setCustomerOnModal(customers.filter(customer => customer.name.toLowerCase().search(nameKey.toLowerCase()) !== -1));
    };

    const handleSelectCustomer = (idSelected) => {
        setCustomerOnModal(customersOnModal.map(ctm => {
            if (idSelected === ctm.id) {
                return {
                    ...ctm,
                    isChecked: !ctm.isChecked,
                };
            }
            return ctm;
        }));
        setCustomers(customers.map(ctm => {
            if (idSelected === ctm.id) {
                return {
                    ...ctm,
                    isChecked: !ctm.isChecked,
                };
            }
            return ctm;
        }));
    };

    return (
        <KirokuModal show={isShowModal}>
            <KirokuModalHeader>
                <h6 className="mb-3 font-size-16">{t('Customer.ChooseCustomersToDeactive')}</h6>
                <KirokuInputSearch
                    value={nameKey}
                    onChange={(event) => {
                        handleSearchName(event.target.value);
                        setNameKey(event.target.value);
                    }}
                    placeholder={t('Customer.EnterCustomersName')}
                />
            </KirokuModalHeader>
            <KirokuModalBody>
                <div id="body-modal-as">
                    <KirokuCardList
                        headerLeft={t('Customer.CustomerList')}
                        headerRight={
                            <If statement={customersOnModal.length}>
                                {() => <div className='text-primary' style={{ cursor: 'pointer' }}>
                                <span hidden={findIndex(customersOnModal, (ctm) => !ctm.isChecked) === -1}
                                      onClick={() => {
                                          handleCheckAll();
                                      }}
                                >{t('common.SelectAll')}</span>
                                    <span hidden={findIndex(customersOnModal, (ctm) => !ctm.isChecked) !== -1}
                                          onClick={() => {
                                              handleCheckAll();
                                          }}
                                    >{t('common.UnSelect')}</span>
                                </div>}
                            </If>
                        }
                    >
                        <section className="scroll-data-customer">
                            <KirokuLoader loading={loading}>
                                <If statement={customersOnModal}>
                                    {() => customersOnModal.map((customer, index) => (
                                        <div key={customer.id}>
                                            <KirokuCheckboxBorder
                                                key={index}
                                                checked={!!customer.isChecked}
                                                onChange={() => handleSelectCustomer(customer.id)}
                                                onClick={() => handleSelectCustomer(customer.id)}
                                                content={customer.name}
                                            />
                                        </div>
                                    ))}
                                </If>
                            </KirokuLoader>
                        </section>
                    </KirokuCardList>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    onClick={handleCancel}
                    color={'white'}
                >
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton disabled={![...customersOnModal].filter(item => item.isChecked).length}
                              onClick={handleConfirm}
                              color={'primary'}>
                    {t('common.Apply')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
