import React, { useEffect }                from 'react';
import { useTranslation }                  from 'react-i18next';
import useSelectedCustomer                 from '../useSelectedCustomer';
import OptionsCustomerMobileTab            from './OptionsCustomerMobileTab';
import SelectedCustomerMobileTab           from './SelectedCustomerMobileTab';
import { KirokuButton, KirokuModalFooter } from '../../../../../../src/ui';
import { useCondition }                    from '../../../useCondition';
import { resources }                       from '@kiroku/api';
import useSearchTeam                       from '../useSearchTeam';
import findIndex                           from 'lodash/findIndex';

export default function ModalChoiceCustomerMobile({ showModal }) {
    const { t } = useTranslation();
    const {
              showModalSelectCustomer,
              setShowModalSelectCustomer,
              selectTab, handleSelectTab,
              handleCloseModalSelect,
              customers,
              setDefaultTabCondition,
              getCustomers,
              getCustomerSearched
          }     = useSelectedCustomer();

    const { updateRecordCondition, recordCondition } = useCondition();
    const { search, result, loading }                = resources.useSearch('Customer');
    const {teams, searchTeams} = useSearchTeam();

    useEffect(() => {
        if(showModal) {
            search({ type: 'all' });
            searchTeams();
        }
    }, [search, showModal, searchTeams]);

    useEffect(() => {
        if (result && showModal) {
            setDefaultTabCondition();
            getCustomers(result);
        }
    }, [getCustomers, result, setDefaultTabCondition, showModal]);

    return (
        <div className='default-background-color' hidden={ !showModalSelectCustomer }>
            <div className="pt-3 pl-1 pr-1">
                <div className="d-flex justify-content-center">
                    <div
                        className={ `border-width-each-status font-text-on-btn first-btn ${ selectTab === 'selectedTab' ? 'btn-active' : '' } ` }
                        onClick={ () => {
                            handleSelectTab('selectedTab');
                        } }
                    >
                        { t('RecordsBeta.displaySelection') }
                    </div>
                    <div
                        className={ `border-width-each-status font-text-on-btn third-btn ${ selectTab === 'optionsTab' ? 'btn-active' : '' }` }
                        onClick={ () => {
                            handleSelectTab('optionsTab');
                        } }
                    >
                        { t('RecordsBeta.showAll') }
                    </div>
                </div>
            </div>
            <div className="pt-1 pb-5-customer-selected" style={ { marginBottom: '25px' } }>
                { selectTab === 'optionsTab' ? <OptionsCustomerMobileTab loading={ loading } teams={teams} show={ showModal }/> :
                    <SelectedCustomerMobileTab teams={teams} show={ showModal }/> }
            </div>
            <div className="fixed-footer" style={ { backgroundColor: '#fff'}}>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => {
                        handleCloseModalSelect();
                        setShowModalSelectCustomer();
                    } } style={ { width: 100 } }
                                  className={ 'btn-cancel-on-modal' }
                    >
                        { t('RecordsBeta.cancel') }
                    </KirokuButton>
                    <div className="align-self-center">
                    </div>
                    <KirokuButton className={ 'btn-confirm-on-modal' }
                                  style={ { width: 100 } }
                                  onClick={ () => {
                                      let customerSearched = customers.filter(customer => customer.selected)
                                          .map(ctm => ctm.id);
                                      let isCheckedAll = findIndex(customers, customer => !customer.selected);
                                      if (isCheckedAll === -1) {
                                          updateRecordCondition({
                                              ...recordCondition,
                                              cId: 'all',
                                              page: 1,
                                              customerTab: 'SEARCH_CUSTOM_CUSTOMER'
                                          });
                                      } else {
                                          updateRecordCondition({
                                              ...recordCondition,
                                              cId: customerSearched,
                                              page: 1,
                                              customerTab: 'SEARCH_CUSTOM_CUSTOMER'
                                          });
                                      }
                                      getCustomerSearched(customerSearched);
                                      setShowModalSelectCustomer();
                                  } }
                    >
                        { t('RecordsBeta.confirm') }
                    </KirokuButton>
                </KirokuModalFooter>
            </div>
        </div>
    );
}
