import React, { Component }      from 'react';
import { KirokuSectionDropdown } from './index';
import chunk                     from 'lodash/chunk';
import head                      from 'lodash/head';
import config                    from '../config';
import { withTranslation }       from 'react-i18next';


class KirokuSectionDropdownShowMoreMobile extends Component {

    state = {
        tagView    : this.props.value,
        tagSelected: this.props.value,
        isOpenMenu : false,
        hasViewMore: false,
        showTagsView: false,
        otherTags  : []

    };

    UNSAFE_componentWillMount() {
        this.setState({
            tagView : this.cutTags(this.props.value)
        })
    }

    showIconSearch() {
        const {isSearchTags} = this.props;
        const {otherTags} = this.state;
        if (isSearchTags) {
            return (
                <div className='submit-search' >
                    <div onClick={this.props.handleSearchTags}>
                        <i className="fa fa-search text--a"/>
                    </div>
                </div>
            )
        } else if(otherTags.length){
            return (
                <div className='submit-search' onClick={this.handleShowTagsView.bind(this)}>
                    <div><i className="fa fa-tags text--a"/></div>
                </div>
            )
        } else {
            return '';
        }
    }

    handleShowTagsView() {
        this.setState({
            showTagsView : !this.state.showTagsView
        })
    }

    cutTags = (itemsSelected) => {
        const {tagType, isMoreTags, t} = this.props;
        const amountOfTag = itemsSelected.length - config.sizeTagOnMobile;
        const  showLabel  = isMoreTags ? `...${t('common.isShowMore')}` : `... ${t(`common.showMore${tagType}`, {value: amountOfTag})}`;
        const removedSelectedTags = [...itemsSelected].map(e => {
            return {
                ...e,
                isClose: true
            }
        });
        if (itemsSelected.length > config.sizeTagOnMobile) {
            const amountTagHidden = itemsSelected.slice(config.sizeTagOnMobile, itemsSelected.length);
            const cutArray = chunk(removedSelectedTags, config.sizeTagOnMobile);
            this.setState({ otherTags : amountTagHidden});
            return [...head(cutArray),
                {
                    label  : showLabel,
                    key    : 'viewMore',
                    type   : 'viewMore',
                    value  : 'viewMore',
                    isClose: true
                }]
        } else {
            this.setState({ otherTags : [],showTagsView: false});
            return itemsSelected;
        }

    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.props.value.length !== nextProps.value.length) {
            this.setState({
                tagSelected: nextProps.value,
                tagView    : this.cutTags(nextProps.value)
            })
        }
    }

    handleChangeTags(options) {
        this.setState({
            tagView    : options,
            tagSelected: options
        }, () => {
            if (!this.state.isOpenMenu) {
                this.handleCloseInput();
            }
        });
    };

    handleOpenInput() {
        const {tagSelected} = this.state;
        this.setState({
                tagView   : tagSelected,
                isOpenMenu: true,
            }
        )
    };

    handleCloseInput() {
        const {tagSelected} = this.state;
        this.props.onChange(tagSelected);
        this.setState({
            tagView   : this.cutTags(tagSelected),
            isOpenMenu: false
        });
    };

    makeColorForTags = (options) =>{
        switch (options.type) {
            case 'Users':
                return '#17a2b8';
            case 'Customers':
                return '#80a42a';
            case 'Teams':
                return '#36af47';
            case 'SurveyList' :
                return '#516173';
            default:
                return '#6E72C0'
        }
    };

    render() {
        const {tagView, otherTags, showTagsView} = this.state;
        const {options, t} = this.props;

        return (
            <div>
                <KirokuSectionDropdown
                    onMenuOpen={this.handleOpenInput.bind(this)}
                    onMenuClose={this.handleCloseInput.bind(this)}
                    onChange={this.handleChangeTags.bind(this)}
                    value={tagView}
                    options={options}
                    searchTags={this.showIconSearch()}
                    placeholder={this.props.placeholder}
                />
                <div className='pt-2' >
                    {otherTags.length ?
                        <div>
                            <span style={{color: '#1891eb'}} onClick={this.handleShowTagsView.bind(this)}>{t('common.isShowMore')} &nbsp;</span>
                            <span hidden={!showTagsView}>
                            {otherTags.map((tag, i) => {
                                    return <span key={i}>
                                    <label className="kiroku-data-view-more mr-1" style={{ backgroundColor : this.makeColorForTags(tag)}}>
                                        <p> {tag.label}</p>
                                    </label>
                                    </span>
                                }
                            )}
                            </span>
                        </div>: ''}
                </div>
            </div>
        )
    }
}

export default withTranslation()(KirokuSectionDropdownShowMoreMobile);
