import React, { useEffect, useRef }  from 'react';
import { Paginator }                 from '../../../../ui';
import { Link, useHistory }          from 'react-router-dom';
import { useTranslation }            from 'react-i18next';
import { resources }                 from '@kiroku/api';
import { useProfile }                from '../../../useProfile';
import { useItemPaginator }          from '../../../useItemPaginator';
import { removeConditionsUndefined } from '../../../Converter';
import TableRecords                  from './TableRecords';
import ComponentWeb                  from '../../../../ui/ComponentWeb';
import ComponentMobile               from '../../../../ui/ComponentMobile';
import TableRecordsMobile            from './TableRecordsMobile';
import { useCondition }              from '../../useCondition';
import queryString                   from 'query-string';
import { useCustomerModalContext }   from '../ModalSelecteCustomers/CustomerModalContext';
import usePageTitle                  from '../../../../ui/v3/hooks/usePageTitle';
import { Cancel }                    from 'axios';
import config                        from '../../../../config';

function isUsingElasticSearch(profile) {
        if (config.orgUsingElasticSearch.includes(profile.organization_id)) {
        return 'elasticsearchCondition'
    }
    return 'condition';
}

export default function RecordTableComponent() {
    const { t }     = useTranslation();
    const cancelRef = useRef(null);
    const {
              search, result,
              pagination, loading, cancelAPISearch
          }         = resources.useSearch('Record');
    const [profile] = useProfile();

    const history             = useHistory();
    const { customerState }   = useCustomerModalContext();
    const { getItems }        = useItemPaginator();
    const { customerDefault } = customerState;
    const {
              resetConditionRecords, recordCondition,
              updateRecordCondition, setPaginatorCondition,
          }                   = useCondition();

    const items = getItems(pagination.totalPage);

    usePageTitle(t(`Layout.Records`));

    useEffect(() => {
        if (localStorage.getItem('conditionRecords')) {
            const conditions = JSON.parse(localStorage.getItem('conditionRecords'));
            updateRecordCondition({ ...conditions });
            localStorage.removeItem('conditionRecords');
        } else {
            let defaultCondition = {
                type: isUsingElasticSearch(profile),
                page: 1,
                ...recordCondition,
            };
            delete defaultCondition.customerTab;
            removeConditionsUndefined(defaultCondition);
            if (cancelRef.current) {
                cancelRef.current()
            }

            search(defaultCondition, null, cancelRef)
                .catch(err => {
                    //ignore sentry log if Request is cancelled
                    if( ! (err instanceof Cancel)) {
                        throw err;
                    }
                    console.log(err);
                });
        }
        return () => {
            cancelAPISearch();
        }
    }, [search, recordCondition, history, updateRecordCondition, cancelAPISearch, profile]);

    const handleCheckRecordRead = (record) => {
        const checkRecordHasRead = record['read_user'].split(',').filter((item) => item.length);
        return checkRecordHasRead.includes(profile.id.toString());
    };

    const conditionToBatchAction = () => {
        const newRecordCondition = { ...recordCondition };
        delete newRecordCondition.customerTab;
        if(!newRecordCondition.serviceStartDate) {
            delete newRecordCondition.serviceStartDate;
        }
        if(!newRecordCondition.serviceEndDate) {
            delete newRecordCondition.serviceEndDate;
        }
        if(!newRecordCondition.updateStartDate) {
            delete newRecordCondition.updateStartDate;
        }
        if(!newRecordCondition.updateEndDate) {
            delete newRecordCondition.updateEndDate;
        }
        return queryString.stringify(newRecordCondition, { arrayFormat: 'comma', skipNull: true, skipEmptyString: true });
    };

    return (
        <div>
            <ComponentWeb>
                <div className={ 'd-flex justify-content-between pt-2 pb-3 pl-0 pr-2' }>
                    <div hidden={ result.length }/>
                    <div className="color-btn-paginator" hidden={ !result.length }>
                        <Paginator
                            height={36}
                            items={ items }
                            numberPage={ pagination.currentPage }
                            onChangePaginator={ (event) => {
                                if(loading) {
                                    return null;
                                }
                                setPaginatorCondition(event.kirokuSelected.value)
                            } }
                            increasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                setPaginatorCondition(pagination.currentPage + 1);
                            } }
                            decreasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                setPaginatorCondition(pagination.currentPage - 1);
                            }}
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                        />
                    </div>
                    <div>
                        <div className="d-flex justify-content-between">
                            <div className={ 'pt-2 font-clear-condition-btn re-native-link' }
                                 onClick={ () => resetConditionRecords(customerDefault) }>
                                { t('RecordsBeta.clearCondition') }
                            </div>
                            <div className={ 'pt-2 pl-1 pr-1' } style={ { color: '#757575' } }> |</div>
                            <div className={ 'group-batch-action pt-2' }>
                                { !(!!result.length && recordCondition.status === 'recorded') ?
                                    <div style={ { color: 'gray' } }>{ t('RecordsBeta.batchActionBtn') }</div> :
                                    <div className={ 'batch-action' }>
                                        <Link to={ {
                                            pathname: `/batch-action-records/record`,
                                            search  : conditionToBatchAction(),
                                        } }
                                              target={ '_blank' }
                                        >{ t('RecordsBeta.batchAction', { count: pagination.totalResult }) }
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className={ 'd-flex justify-content-between p-2' }>
                    <div hidden={ result.length }/>
                    <div className="color-btn-paginator" hidden={ !result.length }>
                        <Paginator
                            height={36}
                            items={ items }
                            numberPage={ pagination.currentPage }
                            onChangePaginator={ (event) => {
                                if(loading) {
                                    return null;
                                }
                                setPaginatorCondition(event.kirokuSelected.value);
                            } }
                            increasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                setPaginatorCondition(pagination.currentPage + 1);
                            } }
                            decreasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                setPaginatorCondition(pagination.currentPage - 1);
                            }}
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                        />
                    </div>
                    <div/>
                    <div className={ 'pt-2 font-clear-condition-btn re-native-link' }
                         onClick={ () => resetConditionRecords(customerDefault) }>
                        { t('RecordsBeta.clearCondition') }
                    </div>
                </div>
            </ComponentMobile>
            <ComponentWeb>
                <TableRecords handleCheckRecordRead={ handleCheckRecordRead } records={ result } loading={ loading }/>
                <div className="color-btn-paginator pt-3" hidden={ !result.length }>
                    <Paginator
                        height={36}
                        location={ 'top' }
                        items={ items }
                        numberPage={ pagination.currentPage }
                        onChangePaginator={ (event) => {
                            if(loading) {
                                return null;
                            }
                            setPaginatorCondition(event.kirokuSelected.value);
                        } }
                        increasePage={ () => {
                            if(loading) {
                                return null;
                            }
                            setPaginatorCondition(pagination.currentPage + 1);
                        } }
                        decreasePage={ () => {
                            if(loading) {
                                return null;
                            }
                            setPaginatorCondition(pagination.currentPage - 1);
                        } }
                        disabledPrev={ pagination.currentPage === 1 }
                        disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                    />
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <TableRecordsMobile handleCheckRecordRead={ handleCheckRecordRead } records={ result }
                                    loading={ loading }/>
                <div className="color-btn-paginator pt-2" hidden={ !result.length } style={ { marginBottom: 80 } }>
                    <Paginator
                        height={36}
                        location={ 'top' }
                        items={ items }
                        numberPage={ pagination.currentPage }
                        onChangePaginator={ (event) => {
                            if(loading) {
                                return null;
                            }
                            setPaginatorCondition(event.kirokuSelected.value);
                        } }
                        increasePage={ () => {
                            if(loading) {
                                return null;
                            }
                            setPaginatorCondition(pagination.currentPage + 1);
                        }}
                        decreasePage={ () => {
                            if(loading) {
                                return null;
                            }
                            setPaginatorCondition(pagination.currentPage - 1);
                        }}
                        disabledPrev={ pagination.currentPage === 1 }
                        disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                    />
                </div>
            </ComponentMobile>
        </div>
    );
}
