import KirokuStaffTag       from './KirokuStaffTag';
import React, { Component } from 'react';
import differenceBy         from 'lodash/differenceBy';
import { Link }             from 'react-router-dom';
import { withTranslation }  from 'react-i18next';

class KirokuStaffTagList extends Component {

    constructor(props) {
        super(props);
        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false,
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        const { t }  = this.props;
        const tags   = this.props['listTags'];
        let tagShow  = tags.slice(0, this.props['numberTags'] || 1);
        let showTags = differenceBy(tags, tagShow, 'id');
        let tagMore  = tags.length - tagShow.length;
        return (
            <span className="m-2 tags-list" ref={ node => {
                this.node = node;
            } }>
                <KirokuStaffTag type={ this.props.type } tag={ tagShow }/>
                <span className="dropdown tags-dropdown" style={ { display: 'inline-block' } } hidden={ tagMore === 0 }>
                    { this.props.type === 'teams' ?
                        <span className="kiroku-btn-data-more"
                              onClick={ this.handleClick.bind(this) }>{ t('common.viewMoreTeam', { value: tagMore }) }</span> :
                        <span className="kiroku-btn-data-more"
                              onClick={ this.handleClick.bind(this) }>{ t('common.viewMore', { value: tagMore }) }</span>
                    }

                    { this.state.popupVisible && (
                        <div className="dropdown-kiroku dropdown-menu headerm show"
                             style={ { position: 'absolute', willChange: 'top, left', width: 'fit-content' } }>
                            <div style={ { maxHeight: '500px', overflowY: 'auto' } }>
                                { showTags.map((tag, index) =>
                                    <div key={ index } className='dropdown-item-kiroku'>
                                        <span className="kiroku-btn-data mr-1">
                                            { this.props.type === 'teams' ?
                                                <Link to={ `/teams/${ tag.tagId ? tag.tagId : tag.id }` }>
                                                    <span>
                                                        { tag.name ? tag.name : tag.value }
                                                    </span>
                                                </Link> :
                                                <Link to={ `/users/${ tag.tagId ? tag.tagId : tag.id }` }>
                                                    { this.props.type === 'users' ? '' :
                                                        <div className="avatar-staff"
                                                             style={ { backgroundImage: `url(${tagShow.avatar || '/assets/img/profile-ic.svg'})` } }/>
                                                    }
                                                    <span>
                                                        { tag.name ? tag.name : tag.value }
                                                    </span>
                                                </Link>
                                            }
                                        </span>
                                    </div>) }
                            </div>
                        </div>
                    ) }
                </span>
            </span>
        );
    }
}

export default withTranslation()(KirokuStaffTagList);
