import { useCallback, useEffect, useState } from 'react';
import { useDocumentContext }               from './DocumentContext';
import {
    SELECT_DOCUMENT_ID,
    HANDLE_SELECT_ALL,
    RESULT_SEARCH_DOCUMENT,
    ACTION_CLOSE_MODAL,
    SET_DOCUMENT_DATA_PREVIEW,
    SHOW_MODAL_RENAME_FILE,
    SHOW_MODAL_CONFIRM_CHANGE_STATUS_TO_PRIVATE,
    SHOW_MODAL_CONFIRM_DELETE_DOCUMENT,
    SHOW_MODAL_SELECT_USER_BE_SHARE,
    RENAME_MODE_ACTION,
} from './DocumentReducer';
import { resources }                               from '@kiroku/api';
import findIndex                                   from 'lodash/findIndex';
import useDropDownData                             from '../../../useDropDownData';

export default function useDocumentAction() {
    const { dispatch, customer, loginUserInfo, documentsData }                               = useDocumentContext();
    const { result, search, loading, pagination, condition }                                 = resources.useSearch('Document');
    const [resultUpdateDocumentStatus, updateStatusDocumentAPI, updateStatusDocumentLoading] = resources.useUpdateByAction('Document');
    const [isDoneUpdateStatusDocument, setIsDoneUpdateStatusDocument]                        = useState(false);
    const [resultDetailDocument, getDetailDocumentAPI, detailDocumentLoading]                = resources.useDetail('Document');
    const shareDocumentAPI                                                                   = resources.useCreate('Document')[ 1 ];

    const { optionDateItems, optionFileTypeItems } = useDropDownData();


    const handleRenameMode = useCallback((documentId) => {
        dispatch({
            type: RENAME_MODE_ACTION,
            documentId,
        });
    }, [dispatch]);


    const handleSelectDocument = useCallback((documentId) => {
        dispatch({
            type: SELECT_DOCUMENT_ID,
            documentId,
        });
    }, [dispatch]);

    const handleSelectedAll = useCallback((isSelectAll) => {
        dispatch({
            type: HANDLE_SELECT_ALL,
            isSelectAll,
        });
    }, [dispatch]);

    const handleSelectType = useCallback((typeSelected) => {
        const newCondition = {
            ...condition,
            type    : 'customer',
            type_id : customer.id,
            fileType: typeSelected,
            page    : 1,
            name    : condition.name,
        };

        if (typeSelected === 'all') {
            delete newCondition.fileType;
        }

        search(newCondition);
    }, [search, customer, condition]);

    const handleSearchKeyword = useCallback((keywordSearch) => {
        const newCondition = {
            ...condition,
            type   : 'customer',
            type_id: customer.id,
            name   : keywordSearch,
            page   : 1,
        };
        if (!keywordSearch) {
            delete newCondition.name;
        }
        search(newCondition);
    }, [search, customer, condition]);

    const handleChangePage = useCallback((page) => {
        search({
            ...condition,
            type   : 'customer',
            type_id: customer.id,
            page,
        });
    }, [search, customer, condition]);

    const handleSearchTime = useCallback((timeSelected) => {
        const newCondition = {
            name                    : condition.name,
            type                    : 'customer',
            fileType                : condition.fileType,
            type_id                 : customer.id,
            page                    : 1,
            [ timeSelected.timeKey ]: timeSelected.timeValue,
        };
        if (!newCondition.name) {
            delete newCondition.name;
        }
        if (!newCondition.fileType) {
            delete newCondition.fileType;
        }
        search(newCondition);
    }, [search, customer, condition]);

    useEffect(() => {
        dispatch({
            type   : RESULT_SEARCH_DOCUMENT,
            loading: loading,
            result, pagination,

        });
    }, [loading, dispatch, result, pagination]);


    const isShareFromUserLogin = (users) => {
        return findIndex(users, userBeShare => userBeShare.id === loginUserInfo.id) !== -1;
    };

    const changeStatusDocument = useCallback((id, status) => {

        updateStatusDocumentAPI('sharing', { id, status }).then(() => {
            setIsDoneUpdateStatusDocument(true);
        });
    }, [updateStatusDocumentAPI]);

    useEffect(() => {
        if (resultUpdateDocumentStatus && !updateStatusDocumentLoading && isDoneUpdateStatusDocument) {
            const newCondition = {
                ...condition,
                type_id: customer.id,
                page   : documentsData.currentPage,
                type   : 'customer',
            };
            search(newCondition);
            setIsDoneUpdateStatusDocument(false);
        }
    }, [resultUpdateDocumentStatus, updateStatusDocumentLoading, search, condition, isDoneUpdateStatusDocument, customer, documentsData]);

    const closeModalName = useCallback(() => {
        dispatch({
            type: ACTION_CLOSE_MODAL,
        });
    }, [dispatch]);

    const showModalPreviewDocument = useCallback((documentId) => {
        getDetailDocumentAPI(documentId);
    }, [getDetailDocumentAPI]);


    useEffect(() => {
        if (resultDetailDocument && !detailDocumentLoading) {
            dispatch({
                type     : SET_DOCUMENT_DATA_PREVIEW,
                modalInfo: {
                    name: 'PREVIEW_DOCUMENT_MODAL',
                    data: resultDetailDocument,
                },
            });
        }
    }, [resultDetailDocument, detailDocumentLoading, dispatch]);

    const handleShowModalEditName = useCallback((document) => {
        dispatch({
            type     : SHOW_MODAL_RENAME_FILE,
            modalInfo: {
                name: 'RENAME_FILE',
                data: document ,
            },
        });
    }, [dispatch])
    const showModalConfirmChangeStatusToPrivate = useCallback((id) => {
        dispatch({
            type     : SHOW_MODAL_CONFIRM_CHANGE_STATUS_TO_PRIVATE,
            modalInfo: {
                name: 'CONFIRM_PUBLIC_DOCUMENT',
                data: { id },
            },
        });
    }, [dispatch]);

    const showModalConfirmDelDocument = useCallback(() => {
        dispatch({
            type     : SHOW_MODAL_CONFIRM_DELETE_DOCUMENT,
            modalInfo: {
                name: 'CONFIRM_DELETE_DOCUMENT',
            },
        });
    }, [dispatch]);

    const refreshDocument = useCallback(() => {
        search({ type: 'customer', type_id: customer.id, page: 1 });
    }, [search, customer]);

    const handleOpenModalSelectUser = useCallback((document) => {
        dispatch({
            type     : SHOW_MODAL_SELECT_USER_BE_SHARE,
            modalInfo: {
                name: 'SELECT_USER_BE_SHARE',
                data: { document },
            },
        });
    }, [dispatch]);

    const handleShareDocumentToUser = useCallback((documentId, users) => {
        shareDocumentAPI({
            users   : users.map(user => user.id),
            document: documentId,
        }, 'assignments').then(() => {
            search({
                ...condition,
                type_id: customer.id,
                page   : documentsData.currentPage,
                type   : 'customer',
            });
            dispatch({ type: ACTION_CLOSE_MODAL });
        });
    }, [shareDocumentAPI, dispatch, search, customer, documentsData, condition]);


    return {
        handleSelectDocument,
        handleSelectedAll,
        handleSelectType,
        handleSearchKeyword,
        handleChangePage,
        handleSearchTime,
        isShareFromUserLogin,
        optionDateItems,
        optionFileTypeItems,
        changeStatusDocument,
        closeModalName,
        showModalPreviewDocument,
        showModalConfirmChangeStatusToPrivate,
        showModalConfirmDelDocument,
        refreshDocument, handleOpenModalSelectUser,
        handleShareDocumentToUser,
        handleShowModalEditName,
        handleRenameMode
    };
}
