import i18n                 from 'i18next';
import Backend              from 'i18next-xhr-backend';
import LanguageDetector     from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const cacheVersion = document.querySelector('meta[http-equiv="Cache-Version"]')?.content;

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng  : 'en',
        ns           : ['translations'],
        defaultNS    : 'translations',
        debug        : false,
        interpolation: {
            escapeValue: false,
        },
        react        : {
            useSuspense: true
        },
        backend      : {
            loadPath: `/locales/{{lng}}/{{ns}}.json?cache_version=${cacheVersion}`,
        },
    });
export default i18n;
