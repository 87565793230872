import React, { useEffect, useState } from 'react';
import { useLookingForTemplate }      from './useLookingForTemplate';
import { useTranslation }             from 'react-i18next';
import SortBy                         from './SortBy';
import {
    Action, BulkAction, ComponentMobile, ComponentWeb, KirokuButton, KirokuCard, KirokuCardList, KirokuCardWeb,
    KirokuCheckBox, KirokuCircleUp, KirokuLoader, KirokuNotFound, KirokuPopoverItem, KirokuTable, Paginator, TBody,
    THeader,
}                                     from '../../../ui';
import KeywordsSearch                 from './KeywordSearch';
import { useItemPaginator }           from '../../useItemPaginator';
import usePageTitle                   from '../../../ui/v3/hooks/usePageTitle';
import * as moment                    from 'moment';
import { Link, useHistory }           from 'react-router-dom';
import ModalSelectCustomers           from './ModalSelectCustomers';
import ModalSelectSurveyAssign        from './ModalSelectSurveyAssign';
import ModalSelectCustomerAssign      from './ModalSelectCustomerAssign';
import findIndex                      from 'lodash/findIndex';
import { withPermissions }            from '../../HOC';
import ThPermission                   from '../../HOC/ThPermission';
import TdPermission                   from '../../HOC/TdPermission';
import { useProfile }                 from '../../useProfile';
import DivTagPermission               from '../../HOC/DivTagPermission';
import '../survey.css';

const KirokuBtnAction = withPermissions(KirokuButton);
const Th              = withPermissions(ThPermission);
const Td              = withPermissions(TdPermission);
const DivPermission   = withPermissions(DivTagPermission);


export default function Templates() {
    const { t }   = useTranslation();
    const history = useHistory();
    const {
              condition, result,
              pagination, search, loading,
          }       = useLookingForTemplate();

    const { getItems }                                          = useItemPaginator();
    const [isShowModalSelectCustomers, showModalSelectCustomer] = useState(false);
    const [showModal, setShowModal]                             = useState('');
    const [templateChecked, setTemplateChecked]                 = useState([]);
    const [templates, setTemplates]                             = useState(result || []);
    const [profile]                                             = useProfile();

    useEffect(() => {
        setTemplates(result);
    }, [result]);
    usePageTitle(t(`Layout.SurveyTemplates`));

    const handleCheckAll    = () => {
        let isCheckAll = findIndex(templates, tm => !tm.isChecked) === -1;
        setTemplates(templates.map(template => {
            return {
                ...template,
                isChecked: !isCheckAll,
            };
        }));
        setTemplateChecked(templates.map(template => {
            return {
                ...template,
                isChecked: !isCheckAll,
            };
        }).filter(template => template.isChecked));
    };

    const handleCheckSurvey = (index) => {
        setTemplates(templates.map((template, i) => {
            if (index === i) {
                return {
                    ...template,
                    isChecked: !template.isChecked,
                };
            }
            return template;
        }));
        setTemplateChecked(templates.map((template, i) => {
            if (index === i) {
                return {
                    ...template,
                    isChecked: !template.isChecked,
                };
            }
            return template;
        }).filter(template => template.isChecked));
    };

    const getCustomerFromCondition = (conditionFilter) => {
        if(typeof conditionFilter.customerIds === 'string') {
            return [parseInt(conditionFilter.customerIds)];
        }
        return conditionFilter.customerIds ? conditionFilter.customerIds.map(id => parseInt(id)) : conditionFilter.customerIds;
    }

    return (
        <div className={ 'padding-responsive' }>
            <div className={ 'padding-none' }>
                <ComponentMobile>
                    <div className={'pb-3 text-align-end'}>
                        <Link to={'/surveys/create'}>
                            <KirokuBtnAction
                                style={{fontSize: 14, height: 36}}
                                resource-permission={'|surveyList|button-new|'}
                                className={'btn-active'}
                            >
                                {t('common.create')}
                            </KirokuBtnAction>
                        </Link>
                    </div>
                </ComponentMobile>
                <div style={ { maxWidth: 400 } }>
                    <SortBy condition={ condition } search={ search }/>
                </div>

                {/*<StatusTemplate condition={ condition } search={ search }/>*/}
                <div className="pt-2">
                    <ComponentWeb>
                        <div className="d-flex">
                            <div style={ { width: '50%' } }>
                                <KeywordsSearch condition={ condition } search={ search }/>
                            </div>
                            <div className={ 'pl-2 d-flex align-items-center' }>
                                <KirokuButton
                                    className={ `${ condition.customerIds ? 'btn-active' : 'btn-deActive' } d-flex justify-content-center align-items-center` }
                                    style={ { height: 36, padding: '2px 10px', fontSize: 14 } }
                                    onClick={ () => showModalSelectCustomer(true) }
                                >
                                    { t('Surveys.selectCustomer') }
                                </KirokuButton>
                                <span className="pl-2"
                                      style={ {
                                          color   : '#757575',
                                          fontSize: 14,
                                      } }
                                >{ condition.customerIds ? t('RecordsBeta.isSelected', { count: getCustomerFromCondition(condition).length }) : t('RecordsBeta.notSelected') }</span>
                            </div>

                        </div>
                    </ComponentWeb>
                    <ComponentMobile>
                        <KeywordsSearch id={ 'input-search-keyword-mobile-surveys-v2' } condition={ condition }
                                        search={ search }/>
                        <div className={ 'pt-2 d-flex align-items-center' }>
                            <KirokuButton
                                className={ `${ condition.customerIds ? 'btn-active' : 'btn-deActive' } d-flex justify-content-center align-items-center` }
                                style={ { height: 36, padding: '2px 10px', fontSize: 14 } }
                                onClick={ () => showModalSelectCustomer(true) }
                            >
                                { t('Surveys.selectCustomer') }
                            </KirokuButton>
                            <span className="pl-2"
                                  style={ {
                                      color   : '#757575',
                                      fontSize: 14,
                                  } }
                            >{ condition.customerIds ? t('RecordsBeta.isSelected', { count: condition.customerIds.length }) : t('RecordsBeta.notSelected') }</span>
                        </div>
                    </ComponentMobile>
                </div>
            </div>
            <div hidden={ !templates.length }>
                <div className="d-flex justify-content-between" style={{ flexWrap: 'wrap' }}>
                    <div className={ 'pt-2' }>
                        <DivPermission resource-permission={ '|surveyList|button-bulkAction|' }>
                            <ComponentWeb>
                                <BulkAction
                                    disabled={findIndex(templates, tm => tm.isChecked) === -1 }
                                    style={ { minWidth: 160 } }
                                    title={ t('common.BulkAction') }
                                    items={ [{
                                        value    : 'selectCustomer',
                                        component: <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                        label    : <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                    }] }
                                    onSelect={ event => {
                                        setShowModal(event.kirokuSelected.value);
                                    } }
                                />
                            </ComponentWeb>
                            <ComponentMobile>
                                <BulkAction
                                    style={ { minWidth: 140 } }
                                    title={ t('common.BulkAction') }
                                    items={ [{
                                        value    : 'selectSurvey',
                                        component: <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                        label    : <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                    }] }
                                    onSelect={ event => {
                                        setShowModal(event.kirokuSelected.value);
                                    } }
                                />
                            </ComponentMobile>
                        </DivPermission>

                    </div>
                    <div/>
                    <div className="color-btn-paginator pt-2 ">
                        <Paginator
                            height={ 36 }
                            items={ getItems(pagination.totalPage).map(pageNumber => {
                                return {
                                    ...pageNumber,
                                    fontSize: 'font-size-14'
                                }
                            })}
                            numberPage={ pagination.currentPage }
                            onChangePaginator={ (event) => {
                                if(loading) {
                                    return null;
                                }
                                search({ ...condition, page: event.kirokuSelected.value });
                            } }
                            increasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                if (pagination.currentPage < pagination.totalPage) {
                                    search({ ...condition, page: pagination.currentPage + 1 });
                                }
                            } }
                            decreasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                if (pagination.currentPage > 1) {
                                    search({ ...condition, page: pagination.currentPage - 1 });
                                }
                            } }
                            disabledPrev={ pagination.currentPage <= 1 }
                            disabledNext={ pagination.currentPage >= pagination.totalPage }
                        />
                    </div>
                </div>
            </div>
            <div className="pt-3"/>
            <KirokuCardWeb>
                <ComponentWeb>
                    <KirokuLoader loading={ loading }>
                        <KirokuNotFound isResults={ templates.length }>
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <Th style={ { minWidth: 50 } }
                                            resource-permission={ '|surveyList|button-bulkAction|'}
                                        >
                                            <KirokuCheckBox onChange={ handleCheckAll }
                                                            checked={ findIndex(templates, tm => !tm.isChecked) === -1 }/>
                                        </Th>
                                        <th style={ { minWidth: 180, fontSize: 14 } }>{ t('common.Title') }</th>
                                        <th style={ { fontSize: 14, minWidth: 400 } }>{ t('common.Description') }</th>
                                        <th style={ { fontSize: 14 } }>{ t('common.Creator') }</th>
                                        <th style={ { minWidth: 80, fontSize: 14 } }>{ t('common.create') }</th>
                                        <th style={ { fontSize: 14 } }>{ t('common.updater') }</th>
                                        <th style={ { minWidth: 80, fontSize: 14 } }>{ t('common.update') }</th>
                                        <th style={ { minWidth: 130 } }/>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {
                                        templates.map((survey, index) =>
                                            <tr key={ index } style={ { fontSize: 14 } }>
                                                <Td
                                                    resource-permission={ '|surveyList|button-bulkAction|'}
                                                >
                                                    <KirokuCheckBox
                                                        onChange={ () => handleCheckSurvey(index) }
                                                        checked={ !!survey.isChecked }/>
                                                </Td>
                                                <td>
                                                    { !survey.delete_at && <Link to={ {
                                                        pathname: `/surveys/${ survey.id }`,
                                                        state   : templates.map(item => item.id),
                                                    } }
                                                                                 onClick={ () => localStorage.setItem('urlSearchSurveys', history.location.search) }
                                                    >
                                                        { survey.title }
                                                    </Link> }
                                                    { survey.delete_at &&
                                                    <span className={ 'font-size-14' }>{ survey.title }</span> }
                                                </td>
                                                <td>{ survey.description }</td>
                                                <td>{ survey['create_by'] }</td>
                                                <td style={ { whiteSpace: 'nowrap' } }>{ moment.unix(survey.create_at).format(t('dateTimeFormat.YearMonthDateWithHour')) }</td>
                                                <td>{ survey['updater_name'] || survey['create_by'] }</td>
                                                <td style={ { whiteSpace: 'nowrap' } }>
                                                    { survey.update_at ? moment.unix(survey.update_at).format(t('dateTimeFormat.YearMonthDateWithHour')) : moment.unix(survey.create_at).format(t('dateTimeFormat.YearMonthDateWithHour')) }</td>
                                                <td>
                                                    <Link to={ `/surveys/preview/${ survey.id }` }
                                                          style={ { color: '#343a40' } }
                                                    >
                                                        <i className="fas fa-binoculars"/>
                                                        <span
                                                            className='ml-1 font-size-14'>{ t('common.Preview') }</span>
                                                    </Link>
                                                </td>
                                            </tr>) }
                                </TBody>
                            </KirokuTable>
                        </KirokuNotFound>
                    </KirokuLoader>
                </ComponentWeb>
                <ComponentMobile>
                    <KirokuLoader loading={ loading }>
                        <KirokuNotFound isResults={ templates.length }>
                            <div>
                                <KirokuCardList
                                    headerLeft={ t('common.Survey') }
                                    headerRight={ t('common.Action') }
                                >
                                    {
                                        templates.map((survey, index) =>
                                            <KirokuCard key={ index }
                                                        action={
                                                            <Action surveyId={ survey.id }>
                                                                <KirokuPopoverItem style={ { width: 120 } }>
                                                                    <div className='p-1 fix-icon-align'>
                                                                        <Link to={ `/surveys/preview/${ survey.id }` }>
                                                                            <i className="fas fa-binoculars"/><span
                                                                            className='ml-1 font-size-14'>{ t('common.Preview') }</span>
                                                                        </Link>
                                                                    </div>
                                                                </KirokuPopoverItem>
                                                            </Action> }
                                            >
                                                { !survey.delete_at && <Link to={ {
                                                    pathname: `/surveys/${ survey.id }`,
                                                    state   : templates.map(item => item.id),
                                                } }
                                                                             onClick={ () => localStorage.setItem('urlSearchSurveys', history.location.search) }
                                                >
                                                    <span className={ 'font-size-14' }>{ survey.title }</span>
                                                </Link> }
                                                { survey.delete_at &&
                                                <span className={ 'font-size-14' }>{ survey.title }</span> }
                                                <p className='pt-10px font-size-14'> { survey.description }</p>
                                                <div
                                                    style={ { color: '#091e42', fontSize: 14, paddingTop: 10 } }>{ t('common.create') }: { moment.unix(survey.create_at).format(t('dateTimeFormat.YearMonthDateWithHour')) }</div>
                                                <div
                                                    style={ { color: '#091e42', fontSize: 14, paddingTop: 10 } }>{ t('common.update') }:&nbsp;
                                                    { survey.update_at ? moment.unix(survey.update_at).format(t('dateTimeFormat.YearMonthDateWithHour')) : moment.unix(survey.create_at).format(t('dateTimeFormat.YearMonthDateWithHour')) }
                                                </div>
                                            </KirokuCard>) }
                                </KirokuCardList>
                            </div>
                        </KirokuNotFound>
                    </KirokuLoader>
                </ComponentMobile>
            </KirokuCardWeb>
            <div className={ 'pt-3' }/>
            <div hidden={ !templates.length }>
                <div className="d-flex justify-content-between" style={{ flexWrap: 'wrap' }}>
                    <ComponentWeb>
                        <div className={`${profile.role === 'staff' ? 'd-none': ''}`}>
                            <BulkAction
                                disabled={findIndex(templates, tm => tm.isChecked) === -1 }
                                style={ { minWidth: 160 } }
                                title={ t('common.BulkAction') }
                                location={ 'top' }
                                items={ [{
                                    value    : 'selectCustomer',
                                    component: <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                    label    : <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                }] }
                                onSelect={ event => {
                                    setShowModal(event.kirokuSelected.value);
                                } }
                            />
                        </div>
                    </ComponentWeb>
                    <ComponentMobile>
                        <div className={`${profile.role === 'staff' ? 'd-none': ''}`}>
                            <BulkAction
                                style={ { minWidth: 140 } }
                                title={ t('common.BulkAction') }
                                location={ 'top' }
                                items={ [{
                                    value    : 'selectSurvey',
                                    component: <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                    label    : <span style={ { fontSize: 14 } }>{ t('Customer.AssignSurvey') }</span>,
                                }] }
                                onSelect={ event => {
                                    setShowModal(event.kirokuSelected.value);
                                } }
                            />
                        </div>
                    </ComponentMobile>
                    <div/>
                    <div className="color-btn-paginator ">
                        <Paginator
                            location={'top'}
                            height={ 36 }
                            items={ getItems(pagination.totalPage).map(pageNumber => {
                                return {
                                    ...pageNumber,
                                    fontSize: 'font-size-14'
                                }
                            }) }
                            numberPage={ pagination.currentPage }
                            onChangePaginator={ (event) => {
                                if(loading) {
                                    return null;
                                }
                                search({ ...condition, page: event.kirokuSelected.value });
                            } }
                            increasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                if (pagination.currentPage < pagination.totalPage) {
                                    search({ ...condition, page: pagination.currentPage + 1 });
                                }
                            } }
                            decreasePage={ () => {
                                if(loading) {
                                    return null;
                                }
                                if (pagination.currentPage > 1) {
                                    search({ ...condition, page: pagination.currentPage - 1 });
                                }
                            } }
                            disabledPrev={ pagination.currentPage <= 1 }
                            disabledNext={ pagination.currentPage >= pagination.totalPage }
                        />
                    </div>
                </div>
            </div>
            <ModalSelectCustomers
                isShowModal={ isShowModalSelectCustomers }
                customerIds={ getCustomerFromCondition(condition) }
                cancel={ () => {
                    showModalSelectCustomer(false);
                } }
                confirm={ (customers) => {
                    showModalSelectCustomer(false);
                    let conditionRequest = {
                        ...condition,
                        page: 1,
                        customerIds: customers.map(customer => customer.id),
                    };
                    if (conditionRequest.customerIds && !conditionRequest.customerIds.length) {
                        delete conditionRequest.customerIds;
                    }
                    search(conditionRequest);
                } }
            />
            <ComponentMobile>
                <div style={ { marginBottom: 80 } } className='pt-2'>
                    <KirokuCircleUp/>
                </div>
            </ComponentMobile>
            <div style={ { marginBottom: 80 } }/>
            <ModalSelectSurveyAssign show={ showModal === 'selectSurvey' }
                                     templates={ templates }
                                     onCloseModal={ () => setShowModal('') }
                                     showModalCustomer={ () => setShowModal('selectCustomer') }
                                     handleCheckedTemplate={ (templatesIsChecked) => {
                                         setTemplateChecked(templatesIsChecked);
                                         setTemplates(templates.map(template => {
                                             return {
                                                 ...template,
                                                 isChecked: findIndex(templatesIsChecked, (item) => item.id === template.id) !== -1,
                                             };
                                         }));
                                     } }
            />
            <ModalSelectCustomerAssign show={ showModal === 'selectCustomer' }
                                       templates={ templateChecked }
                                       onCloseModal={ () => setShowModal('') }
            />
        </div>
    );
}
