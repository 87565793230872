import React, { useEffect, useState }                                               from 'react';
import { KirokuButton, KirokuCardWeb, KirokuCheckBox, KirokuTable, TBody, THeader } from '../../../src/ui';
import { useTranslation }                                                           from 'react-i18next';
import findIndex                                                                    from 'lodash/findIndex';
import { resources }                                                                from '@kiroku/api';
import momentTZ                                                                     from 'moment-timezone';
import moment                                                                       from 'moment';

export default function ExportResults({ records, condition, customerName }) {
    const { t }                             = useTranslation();
    const [exports]                         = resources.useExport('ExportRecord');
    const [recordExports, setRecordExports] = useState([]);

    useEffect(() => {
        setRecordExports(records);
    }, [records]);

    let checkedRecords = recordExports.filter(record => record.isChecked);
    const handleCheckAll = () => {
        const isCheckAll = findIndex(recordExports, record => record.isChecked) !== -1;
        setRecordExports(recordExports.map(record => ({
            ...record,
            isChecked: !isCheckAll,
        })));
    };

    const checkingRecord = (index) => {
        setRecordExports(recordExports.map((record, recordIndex) => {
            if (recordIndex === index) {
                return {
                    ...record,
                    isChecked: !record.isChecked,
                };
            }
            return record;
        }));
    };

    const handleExportToExcel = () => {
        let recordIds = checkedRecords.map(record => record.id);
        let dataReq   = {
            records     : recordIds,
            customerName: customerName,
            serviced    : {
                startDate: condition.startDate_serviced,
                endDate  : condition.endDate_serviced,
            },
            lang        : localStorage.getItem('i18nextLng') || 'ja',
            timezone    : momentTZ.tz.guess(),
        };
        exports('customer/excel', dataReq).then(response => {
            let linkDownload = document.getElementById('downloadExcel');
            linkDownload.setAttribute('href', response.url);
            linkDownload.click();
        });
    };

    return (
        <div className='pb-3'>
            <KirokuCardWeb>
                <div className='padding-responsive card-title-info'>{ t('Export.Result') }</div>
                <div>
                    <KirokuTable>
                        <THeader>
                            <tr>
                                <th style={ { width: 50 } }>
                                    <KirokuCheckBox onChange={ handleCheckAll }
                                                    checked={ findIndex(recordExports, record => !record.isChecked) === -1 }/>
                                </th>
                                <th>{ t('common.Title') }</th>
                                <th>{ t('Export.RecordCreator') }</th>
                                <th>{ t('RecordList.servicedAt') }</th>
                            </tr>
                        </THeader>

                        <TBody>
                            { recordExports.map((record, index) =>
                                <tr key={ record.id }>
                                    <th>
                                        <KirokuCheckBox
                                            onChange={ () => checkingRecord(index) }
                                            checked={ !!record.isChecked }
                                        />
                                    </th>
                                    <td><span>{ record.title }</span></td>
                                    <td><span>{ record.user.name }</span></td>
                                    <td>
                                        {moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                                    </td>
                                </tr>,
                            ) }
                        </TBody>
                    </KirokuTable>
                    <div className={ 'p-3' }>
                        <KirokuButton color={ 'primary' }
                                      disabled={ findIndex(recordExports, records => records.isChecked) === -1 }
                                      onClick={ handleExportToExcel }
                        >
                            { t('Export.ExportToExcel') }
                        </KirokuButton>
                        <a href={ 'xxx' } id={ 'downloadExcel' } hidden download>xxx</a>
                    </div>
                </div>
            </KirokuCardWeb>
        </div>
    );
}
