import React, { useEffect }              from 'react';
import { ComponentMobile, ComponentWeb } from '../../../ui';
import SupportTable                      from './SupportTable';
import SupportTableMobile                from './SupportTableMobile';
import { resources }                     from '@kiroku/api';
import { useTranslation }                from 'react-i18next';
import findIndex                         from 'lodash/findIndex';
import sortBy                            from 'lodash/sortBy';
import arrayMove                         from 'array-move';
import { Link }                          from 'react-router-dom';

export default function Supports({ plan, showModal, hasMonitor }) {
    const { search, result, loading, cancelAPISearch } = resources.useSearch('Support');
    const { t }                                        = useTranslation();
    const update                                       = resources.useUpdate('Support')[1];

    useEffect(() => {
        if (plan.id) {
            search({
                type  : 'plan',
                planId: plan.id,
            });
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, plan.id, cancelAPISearch]);

    useEffect(() => {
        if (result) {
            hasMonitor(findIndex(result, (support) =>  {
                if (support.review) {
                    return support.review.is_draft
                }
                return support;
            }) === -1);
        }

        if (result.length) {
            const supportsPlan       = sortBy(result, ['priority']);
            const prioritySupportMax = supportsPlan[supportsPlan.length - 1];
            localStorage.setItem('prioritySupportMax', prioritySupportMax.priority + 1);
        } else {
            localStorage.setItem('prioritySupportMax', '1');
        }
    }, [result, hasMonitor]);

    const sortableResults = (() => {
        return sortBy(result, ['priority']);
    });

    const handlePriority = (oldIndex, newIndex, supports) => {
        if (newIndex === oldIndex) {
            return null;
        }
        if (newIndex <= supports.length - 1) {
            const newSupports  = arrayMove(supports, oldIndex, newIndex);
            const supportArray = [];
            newSupports.forEach((support, index) => {
                const priority = index + 1;
                if (priority !== support.priority) {
                    const supportData =  { ...support, priority, assignment: support.surveys, unassignment: [] };
                    delete supportData.surveys
                    supportArray.push(update(support.id, supportData));
                }
            });
            Promise.all(supportArray).then((resolve, reject) => {
                search({
                    type  : 'plan',
                    planId: plan.id,
                });
            });
        }
    };

    return (<>
        <div className={ 'bg-white' }>
            <div className="padding-responsive">
                <div className={ 'd-flex justify-content-between' }>
                    <div style={ { fontSize  : 18 } }
                    > { t('plans.content') }</div>
                    <div/>
                    <div hidden={ plan.published_at || plan.review_at }>
                        <div
                            className={ 'd-flex align-items-center add-support-button' }
                        >
                            <Link to={ {
                                pathname: '/supports/create',
                                search  : `planId=${ plan.id }&customerId=${ plan.customer_id }`,
                            } }
                            >
                                <span className="pl-2 pr-2" style={ { color: '#fff' } }>
                                    { t('plans.addSupport') }
                                </span>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ComponentWeb>
                    <SupportTable handlePriority={ handlePriority } plan={ plan } result={ sortableResults() }
                                  loading={ loading } show={ showModal === '' }/>
                </ComponentWeb>
                <ComponentMobile>
                    <SupportTableMobile handlePriority={ handlePriority } plan={ plan } result={ sortableResults() }
                                        loading={ loading } show={ showModal === '' }/>
                </ComponentMobile>
            </div>
        </div>
    </>);
}
