import moment  from 'moment';
import groupBy from 'lodash/groupBy';
import config  from '../../../config';


export function groupRecordByDay(records) {
    const convertDate = records.map(record => {
        return {
            ...record,
            servicedDateRecord: moment(record.serviced_at * 1000).endOf('day').unix(),
        };
    });
    let groupDate     = groupBy(convertDate, 'servicedDateRecord');
    return Object.keys(groupDate).map(key => {
        const records = groupDate[ key ].map(item => {
            const questions = item[ 'survey_template' ].flowedQuestions || item[ 'survey_template' ].questions;

            return {
                ...item,
                survey_template: {
                    ...item[ 'survey_template' ],
                    flowedQuestions: questions.filter(question => config.questionsTypeAnalyst.includes(question.type)),
                },
            };
        });
        return {
            day                : moment.unix(key).format(config.formatDateYMD),
            recordOnlyQuestions: records,
            servicedAtForChart : moment.unix(key).format('YYYY/M/D'),
            customerId         : records.map(item => item.customer_id)[ 0 ],
            templateId         : records.map(item => item.survey_id)[ 0 ],
        };
    });
}
