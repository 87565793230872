import groupBy              from 'lodash/groupBy';
import moment               from 'moment';
import { getFlowQuestions } from '../HelperComponent/getQuestions';

export function buildDateToAnalysis(momentDate) {
    if (moment.locale() === 'en') {
        return momentDate.format('YYYY/D/M(ddd)');
    }
    return momentDate.format('YYYY年M月D日(ddd)');
}


export default function useNumberChart(records, question) {
    let questionsFlowRecords = records.map(record => {
        return {
            ...getFlowQuestions(record.survey_template)
                .filter(item => item.content === question.content && item.type === question.type && question.type === 'Number')
                .map((itm, index) => {
                return {
                    ...itm,
                    servicedDate: buildDateToAnalysis(moment.unix(record.serviced_at).startOf('day')),
                };
            }),
        };
    }).map(itm => itm[ '0' ]).filter(itm => itm);

    let questionsGroupByDay = groupBy(questionsFlowRecords, 'servicedDate');
    let listKeyObject       = Object.keys(questionsGroupByDay);
    listKeyObject.forEach((key, index) => {
        questionsGroupByDay[ key ] = questionsGroupByDay[ key ].map(item => {
            return {
                ...item,
                keyQuestion: index + 1,
            };
        });
    });

    let data = [];

    listKeyObject.forEach(key => {
        data.push(...questionsGroupByDay[ key ]);
    });


    let ticks = listKeyObject.map((key, index) => {
        return {
            v: index + 1,
            f: key,
        };
    });
    return {
        data     : data.map(question => {
            return [
                {
                    v: question.keyQuestion,
                    f: question.servicedDate,
                },
                parseFloat(question.answers),
            ];
        }),
        ticks,
        maxColumn: listKeyObject.length,
        isNotDataAnalysis: questionsFlowRecords.filter(question => !question.answers).length === questionsFlowRecords.length
    };
}
