import { DeepDiff }       from 'deep-diff';
import findIndex          from 'lodash/findIndex';
import { useTranslation } from 'react-i18next';

export default function useCustomerInformation(oldProfile, logProfile) {
    const diff = DeepDiff.diff;
    const {t} = useTranslation();

    const oldData = {
        contract_start: oldProfile.contract_start,
        contract_end  : oldProfile.contract_end,
        classification: oldProfile.classification,
    };

    const logData = {
        contract_start: logProfile.contract_start,
        contract_end  : logProfile.contract_end,
        classification: logProfile.classification,
    };

    return {
        customerInfoDiffs: diff(oldData, logData),
        certificateDiffs : {
            isDiff: JSON.stringify(oldProfile.certificate) !== JSON.stringify(logProfile.certificate),
            lhs: oldProfile.certificate.map(item => {
                return {
                    beRemoved: findIndex(logProfile.certificate, (logItm) => logItm === item) === -1,
                    label    : t(`customerDifference.${item}`),
                };
            }),
            rhs: logProfile.certificate.map(item => t(`customerDifference.${item}`)),
        },
    };
}
