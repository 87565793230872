import head   from 'lodash/head';
import last   from 'lodash/last';
import moment from 'moment';

const concatActivitiesComment = (newLog, oldLog) => {
    const newLogCover = [...newLog];
    const oldLogCover = [...oldLog];
    if (head(newLogCover).date === last(oldLogCover).date) {
        const date = head(newLogCover).date;
        const logs = head(newLogCover).logs.concat(last(oldLogCover).logs);

        oldLogCover.splice(oldLogCover.length - 1, 1);
        newLogCover.splice(0, 1);
        oldLogCover.push({ date, logs });

        return oldLogCover.concat(newLogCover);
    }
    return oldLogCover.concat(newLogCover).map(itemLog => {
        return {
            ...itemLog,
            commentsData: itemLog.logs.reverse()
        }
    });
};

const getGroupCommentByDay = (resultLogs) => {

    const groups = resultLogs.reduce((groups, logs) => {
        const date = moment.unix(logs.create_at).startOf('day').unix();
        if (!groups[ date ]) {
            groups[ date ] = [];
        }
        groups[ date ].push(logs);
        return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date,
            commentsData: groups[ date ].reverse(),
        };
    });
    return groupArrays.sort((a, b) => parseFloat(b.date) - parseFloat(a.date));
};

export {
    concatActivitiesComment,
    getGroupCommentByDay
}
