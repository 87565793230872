import React                                               from 'react';
import { KirokuCheckboxBorder, KirokuInput, KirokuLoader } from '../../../../../src/ui';
import findIndex                                           from 'lodash/findIndex';
import { useTranslation }                                  from 'react-i18next';
import useSelectedCustomer                                 from './useSelectedCustomer';
import DropdownSelectTeam                                  from '../SelectUpdater/DropdownSelectTeam';

export default function CustomerOptionsTab(props) {
    const { t } = useTranslation();
    const {
              customersShowInModal,
              handleCheckAllCustomers,
              handleCheckCustomer,
              handleSearchKeywords,
              keywords,
              handleChangeTags,
              handleResetCondition,
          }     = useSelectedCustomer();

    return (
        <div className="pt-3">
            <div className={ 'pt-2 pb-2 kiroku-section-drop-down-show-more-scroll' }>
                <DropdownSelectTeam handleChangeTags={ (value) => handleChangeTags(value) }
                                    showModal={ props.show }
                                    placeholder={ t('RecordsBeta.refineByTeam') }
                                    teamSearch={ props.teams }
                />
            </div>
            <div className={ 'pt-2' }>
                <KirokuInput
                    style={ { height: 36, fontSize: 14 } }
                    placeholder={ t('RecordsBeta.filterByCustomerName') }
                    value={ keywords }
                    onChange={ (e) => handleSearchKeywords(e.target.value) }
                />
            </div>
            <div style={ { padding: '15px 1px 5px 1px' } }/>
            <div className='background-color'
                 style={ {
                     height :  'calc(100vh - 450px)'
                 } }
            >
                <KirokuLoader loading={ props.loading }>
                    <div>
                        <div className={ `p-3 ${!customersShowInModal.length ? 'd-none': 'd-flex justify-content-between align-items-center '}` }
                             style={ { border: 'solid 1px #dfe2e5', } }>
                            <div className={'d-flex align-items-center'}>
                                <label className="check-group">
                                    <input type="checkbox" checked={ findIndex(customersShowInModal, (ctm) => (!ctm.selected)) === -1 }
                                           onChange={ handleCheckAllCustomers }/>
                                    <span className="checkmark"/>
                                </label>
                                <span className={`font-text-content-in-modal pl-3`}>&nbsp;</span>
                            </div>
                            <div onClick={ handleResetCondition }
                                 className={ 'cursor-pointer font-reset-user-changed font-size-16' }
                                 style={ { float: 'right', color: '#007BFF' } }
                            >
                                { t('RecordsBeta.resetSelection') }
                            </div>
                        </div>
                        <div
                            style={ {
                                overflow : 'scroll',
                                height :  'calc(100vh - 500px)'
                            } }
                        >
                            { customersShowInModal.length ?
                                customersShowInModal.map((customer, i) => {
                                    return (
                                        <div key={ i } onClick={ () => handleCheckCustomer(i) }>
                                            <div>
                                                <KirokuCheckboxBorder
                                                    content={ <span
                                                        className={ 'font-text-in-checkbox cursor-pointer' }>{ customer.name }</span> }
                                                    id={ `${ customer.id }-${ i }` }
                                                    checked={ !!customer.selected }
                                                    onChange={ () => handleCheckCustomer(i) }
                                                />
                                            </div>
                                        </div>
                                    );
                                }) : <div className='background-color d-flex'
                                          style={ {
                                              height : 'calc(100vh - 450px)',
                                              fontSize : 14,
                                          } }
                                >
                                    <div style={ { margin: 'auto' } }>
                                        { t('RecordsBeta.noCustomers') }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </KirokuLoader>
            </div>
        </div>
    );
}
