import filter                              from 'lodash/filter';
import uniqBy                              from 'lodash/uniqBy';
import { Charts, getQuestionsFromRecords } from '../Charts';
import config                              from '../../../config';

function removeRecordWithOldDataQuestion(records) {
    const isOldData = records.map(record => !! filter(record.survey_template.questions, (question) => {
            return (question.type === 'Single' || question.type === 'MultiChoice') && (typeof question.choices[ 0 ]) === 'string';
        },
    ).length);

    return records.filter((record, index) => !isOldData[ index ]);

}

export function useAnalyticalSystem(records) {
    records       = removeRecordWithOldDataQuestion(records);
    let questions = getQuestionsFromRecords(records);

    questions = uniqBy(questions, question => [question.type, question.content].join())
        .filter(question => config.questionsTypeAnalyst.includes(question.type))
        .sort((a, b) => parseFloat(a.index) - parseFloat(b.index));

    return {
        questions: questions.map(question => {
            return {
                chart: Charts[question.type],
                type: question.type, content: question.content, heading: question.heading
            }
        })
    };
}
