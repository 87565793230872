import React, { useContext }  from 'react';
import findIndex              from 'lodash/findIndex';
import { availableQuestions } from '../QuestionTypeWeb';

const DataRecordEditContext = React.createContext(null);

export function useDataRecordEditContext() {
    return useContext(DataRecordEditContext);
}

export default function ConvertDataRecordEditProvider(props) {
    const { record} = props;
    const recordGetFlowedQuestion = {
        ...record,
        survey_template: {
            ...record[ 'survey_template' ],
            flowedQuestions: record[ 'survey_template' ].flowedQuestions ? record[ 'survey_template' ].flowedQuestions : record[ 'survey_template' ].questions
        },
    };
    const survey     = {
        ...recordGetFlowedQuestion[ 'survey_template' ],
        questions  : record[ 'survey_template' ].questions.map(question => {
            return {
                ...question,
                isDisabled: findIndex(recordGetFlowedQuestion[ 'survey_template' ].flowedQuestions, (qs) => qs.index === question.index) === -1,
            };
        }),
        serviced_at: record.serviced_at,
        has_time   : record.has_time,
    };

    const disabledRange = recordGetFlowedQuestion[ 'survey_template' ].flowedQuestions.reduce((acc, question) => {
        const next = availableQuestions[ question.type ].getNext(question);
        if (next > question.index + 1) {
            return {
                ...acc,
                [ question.index ]: next,
            };
        }
        return acc;
    }, {});

    return <DataRecordEditContext.Provider
        value={{ survey, disabledRange, record }}
        {...props}
    />;
}
