import React, { useEffect }                                                                 from 'react';
import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter, KirokuModalHeader } from '../../../../ui';
import { useTranslation }                                                                   from 'react-i18next';
import UserSelectedTab                                                                      from './UserSelectedTab';
import UserOptionsTab                                                                       from './UserOptionsTab';
import { resources }                                                                        from '@kiroku/api';
import { useSelectUsers }                                                                   from './useSelectUsers';
import { useCondition }                                                                     from '../../useCondition';
import useSearchTeam
                                                                                            from '../ModalSelecteCustomers/useSearchTeam';
import findIndex                                                                            from 'lodash/findIndex';

export default function ModalSelectUpdater(props) {
    const { t }                                      = useTranslation();
    const { search, result, loading }                = resources.useSearch('User');
    const { updateRecordCondition, recordCondition } = useCondition();
    const {
              getUsers, userList,
              isSelectedTab, handleChangeTab,
              handleSearchKeywords, handleSearchKeywordsSelected,
              handleResetState
          }                                          = useSelectUsers();

    const {teams, searchTeams} = useSearchTeam();

    useEffect(() => {
        if (props.show) {
            search({
                type: 'all',
                include_admin: true
            });
            handleResetState();
            searchTeams();
        }
    }, [search, props.show, handleSearchKeywords, handleSearchKeywordsSelected, searchTeams, handleResetState]);

    useEffect(() => {
        if (result.length) {
            getUsers(result);
        }
    }, [result, getUsers]);

    return (
        <KirokuModal show={ props.show }
                     onCloseModal={ props.closeModal }>
            <KirokuModalHeader>
                <div className={ 'font-header-on-modal' }>{ t('RecordsBeta.selectStaff') }</div>
            </KirokuModalHeader>
            <KirokuModalBody className={'p-3'}>
                <div className="d-flex justify-content-around border-btn-status-group">
                    <div
                        className={ `border-width-each-status first-btn cursor-pointer 
                        ${ isSelectedTab === 'selectedTab' ? 'btn-active' : '' }` }
                        onClick={ () => handleChangeTab('selectedTab') }>
                        { t('RecordsBeta.displaySelection') }
                    </div>
                    <div
                        className={ `border-width-each-status third-btn cursor-pointer 
                        ${ isSelectedTab === 'optionTab' ? 'btn-active' : '' }` }
                        onClick={ () => handleChangeTab('optionTab') }>
                        { t('RecordsBeta.showAll') }
                    </div>
                </div>
                { isSelectedTab === 'selectedTab' ? <UserSelectedTab teams={teams} show={props.show}/> :
                    <UserOptionsTab loading={ loading } teams={teams} show={props.show}/>
                }
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ () => {
                    props.closeModal();
                    getUsers(result);
                } }
                              style={ { height: 36}}
                              color={'light'}
                >
                    { t('RecordsBeta.cancel') }
                </KirokuButton>
                <KirokuButton color="primary"
                              style={ { height: 36}}
                              onClick={ () => {
                                  let userChecked = userList.filter(user => user.isChecked).map(itm => itm.id);
                                  let isCheckedAll = findIndex(userList, user => !user.isChecked);
                                  if (isCheckedAll === -1) {
                                      updateRecordCondition({
                                          ...recordCondition,
                                          udId: 'all',
                                          page: 1,
                                      });
                                  } else {
                                      updateRecordCondition({
                                          ...recordCondition,
                                          udId: userChecked,
                                          page: 1,
                                      });
                                  }
                                  props.closeModal();
                              } }
                >
                    { t('RecordsBeta.confirm') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
