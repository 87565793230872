import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { resources }                  from '@kiroku/api';
import { Link, useHistory }           from 'react-router-dom';
import usePageTitle                   from '../../../ui/v3/hooks/usePageTitle';
import findIndex                      from 'lodash/findIndex';

import ComponentWeb    from '../../../ui/ComponentWeb';
import ComponentMobile from '../../../ui/ComponentMobile';

import { withPermissions }           from '../../HOC';
import TdPermission                  from '../../HOC/TdPermission';
import ThPermission                  from '../../HOC/ThPermission';
import LinkPermission                from '../../HOC/LinkPermission';
import DivTagPermission              from '../../HOC/DivTagPermission';
import {
    KirokuButton, KirokuCard, KirokuCardList, KirokuCardWeb, KirokuCheckBox, KirokuInputSearcher, KirokuLabel,
    KirokuLoader, KirokuNotFound, KirokuPagination, KirokuPopoverItem, KirokuTable, TBody, THeader,
}                                    from '../../../ui';
import queryString                   from 'query-string';
import BulkActionSetRoleUser         from './BulkActionSetRoleUser';
import ModalSetRoleLeader            from './ModalSetRoleLeader';
import ModalSetRoleStaff             from './ModalSetRoleStaff';
import DropdownSelectCustomers       from './DropdownSelectCustomers';
import DropdownSelectTeams           from './DropdownSelectTeams';
import ModalConfirmDeleteUser        from './ModalConfirmDeleteUser';
import ModalFilterUser               from './ModalFIlterUser';
import LabelCondition                from '../../Templates/SurveyList/LabelCondition';
import { makeParamsFromCondition }   from '../../Converter/makeConditionForLabel';
import { removeConditionsUndefined } from '../../Converter';
import BulkActionSetRoleMobile       from './Mobile/BulkActionSetRoleMobile';
import ModalSelectUserSetRoleLeader  from './Mobile/ModalSelectUserSetRoleLeader';
import ModalSelectUserSetRoleStaff   from './Mobile/ModalSelectUserSetRoleStaff';
import Action                        from '../../../ui/Action';
import ButtonPermission              from '../../HOC/ButtonPermission';
import useNotification               from '../../Layout/useNotification';
import { getKeyWordSearch }          from '../../ServiceSearch';

const Td                        = withPermissions(TdPermission);
const Th                        = withPermissions(ThPermission);
const LinkTo                    = withPermissions(LinkPermission);
const DivPermission             = withPermissions(DivTagPermission);
const ButtonLinkPermission      = withPermissions(ButtonPermission);
const BulkActionSetRole         = withPermissions(BulkActionSetRoleUser);
const BulkActionSetRoleOnMobile = withPermissions(BulkActionSetRoleMobile);
const ActionPermission          = withPermissions(Action);

export default function UserList() {
    const { t } = useTranslation();
    usePageTitle(t(`Layout.Staffs`));
    const {
              result, search, condition,
              pagination, loading,
              nextPage, prevPage, cancelAPISearch,
          }                                       = resources.useSearch('User', { type: 'condition', page: 1 });
    const [users, setUsers]                       = useState([]);
    const [showModal, setShowModal]               = useState('');
    const history                                 = useHistory();
    const [keySearch, setKeySearch]               = useState('');
    const [teamSelected, setTeamSelected]         = useState([]);
    const [customerSelected, setCustomerSelected] = useState([]);
    const [deletedUser, setDeletedUser]           = useState({});

    const { notification } = useNotification();

    const handleSetInitCondition = (conditionUrl) => {
        if (conditionUrl.customers && typeof conditionUrl.customers === 'string') {
            setCustomerSelected([{id: conditionUrl.customers}]);
        } else {
            setCustomerSelected(conditionUrl.customers ? conditionUrl.customers.map(id => {
                return { id };
            }) : []);
        }

        if (conditionUrl.teams && typeof conditionUrl.teams === 'string') {
            setTeamSelected([{id: conditionUrl.teams}]);
        } else {
            setTeamSelected(conditionUrl.teams ? conditionUrl.teams.map(id => {
                return { id };
            }) : []);
        }
    };

    useEffect(() => {
        try {
            const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });
            if(conditionUrl.name) {
                conditionUrl.name = getKeyWordSearch(history.location.search.replace('?', ''), 'name')
            }
            if (conditionUrl.type) {
                handleSetInitCondition(conditionUrl);
                search(conditionUrl);
            } else {
                search({ type: 'condition', page: 1 });
            }
        } catch (e) {
            search({ type: 'condition', page: 1 });
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, history, cancelAPISearch]);

    useEffect(() => {
        removeConditionsUndefined(condition);
        const urlQuery = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
        history.replace('/users?' + urlQuery);
    }, [condition, history]);

    useEffect(() => {
        try {
            const conditionQuery = history.location.search.replace('?', '');
            const urlQuery       = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
            if (conditionQuery !== urlQuery) {
                const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });
                handleSetInitCondition(conditionUrl);
                if (conditionUrl.type) {
                    search(conditionUrl);
                } else {
                    search(condition);
                }
            }
        } catch (e) {
            search(condition);
        }
    }, [history.location.search, condition, history, search]);


    useEffect(() => {
        if (result) {
            setUsers(result);
        }
    }, [result]);

    useEffect(() => {
        setKeySearch(condition.name || '');
    }, [condition.name]);

    const handleSearchTags = () => {
        let teamIds      = teamSelected.map(team => team.id);
        let customerIds  = customerSelected.map(customer => customer.id);
        let newCondition = {
            ...condition,
            customers: customerIds,
            teams    : teamIds,
            name     : keySearch,
        };
        if (!teamIds.length) {
            delete newCondition['teams'];
        }

        if (!customerIds.length) {
            delete newCondition['customers'];
        }
        if(!newCondition.name) {
            delete newCondition.name;
        }

        search({
            ...newCondition,
            page: 1,
        });
    };

    const handleCheckAll = () => {
        const isCheckAll = findIndex(users, user => !user.isChecked) === -1;
        setUsers(users.map(user => {
            return {
                ...user,
                isChecked: !isCheckAll,
            };
        }));
    };

    const handleCheckUser = (index) => {
        setUsers(users.map((user, userIndex) => {
            if (index === userIndex) {
                return {
                    ...user,
                    isChecked: !user.isChecked,
                };
            }
            return user;
        }));
    };

    const checkedUsers = users.filter(user => user.isChecked);

    const makeLabelCondition = {
        statusUser: condition.status || '',
    };
    removeConditionsUndefined(makeLabelCondition);

    const handleRemoveCondition = (conditionRemoved) => {
        let newCondition = Object.keys(condition).map(key => {
            return {
                value: condition[key],
                key  : key,
            };
        });
        let getCondition = newCondition.filter(condition => condition.value !== conditionRemoved.value);
        let newParams  = makeParamsFromCondition(getCondition);

        let teamIds      = teamSelected.map(team => team.id);
        let customerIds  = customerSelected.map(customer => customer.id);

        newParams = {
            ...newParams,
            customers: customerIds,
            teams: teamIds,
            name: keySearch
        }

        if (!teamIds.length) {
            delete newParams['teams'];
        }

        if (!customerIds.length) {
            delete newParams['customers'];
        }
        if(!newParams.name) {
            delete newCondition.name;
        }
        search({
            ...newParams,
            page: 1,
        });
    };

    return (
        <div className='survey-list default-background-color'>
            <div className='padding-responsive' hidden={ showModal === 'filter' }>
                <ComponentWeb>
                    <div className={ `${ result.length ? 'd-flex justify-content-between align-items-baseline' : 'd-none' }` }>
                        <BulkActionSetRole
                            style={{width: 148}}
                            title={ t('RecordList.BulkAction') }
                            resource-permission={ '|staffList|button-bulkAction|' }
                            onSelect={ (event) => setShowModal(event.kirokuSelected.value) }
                            disabled={ findIndex(users, user => user.isChecked) === -1 }
                        />
                        <KirokuPagination
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                            increasePage={ nextPage }
                            decreasePage={ prevPage }
                            currentPage={ pagination.currentPage }
                            totalPage={ pagination.totalPage }
                        />
                    </div>
                </ComponentWeb>
                <ComponentMobile>
                    <div className={ 'd-flex justify-content-between' }>
                        <KirokuButton color={ 'white' } onClick={ () => setShowModal('filter') }>
                            <span className={ 'pr-2' }>{ t('Surveys.FilterBy') }</span>
                            <i className='fa fa-chevron-down text-secondary'
                               style={ { fontSize: '10px' } }/>
                        </KirokuButton>
                        <div>
                            <Link to={ '/users/create' }>
                                <ButtonLinkPermission color={ 'primary' }
                                                      resource-permission={ '|staffList|button-new|' }
                                >
                                    { t('common.AddStaff') }
                                </ButtonLinkPermission>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <LabelCondition condition={ makeLabelCondition } className={ 'pl-0 pt-2 pb-2' }
                                        handleRemoveCondition={ (conditionRemove) => handleRemoveCondition(conditionRemove) }/>
                    </div>
                </ComponentMobile>
                <DropdownSelectTeams teamTagIds={ condition.teams }
                                     handleChangeTeamTags={ (value) => setTeamSelected(value) }
                                     handleSearchTags={ handleSearchTags }/>
                <DropdownSelectCustomers customerTagIds={ condition.customers }
                                         handleChangeCustomerTags={ (value) => setCustomerSelected(value) }
                                         handleSearchTags={ handleSearchTags }/>
                <ComponentMobile>
                    <div style={ { minWidth: 100 } }>
                        <KirokuInputSearcher
                            value={ keySearch }
                            onChange={ (e) => setKeySearch(e.target.value) }
                            id={ 'InputSearcherMobile' }
                            onSubmit={ () => {
                                let teamIds      = teamSelected.map(team => team.id);
                                let customerIds  = customerSelected.map(customer => customer.id);
                                let newCondition = {
                                    ...condition,
                                    customers: customerIds,
                                    teams    : teamIds,
                                    name     : keySearch,
                                };

                                if (!teamIds.length) {
                                    delete newCondition['teams'];
                                }

                                if (!customerIds.length) {
                                    delete newCondition['customers'];
                                }
                                if(!newCondition.name) {
                                    delete newCondition.name;
                                }

                                search({
                                    ...newCondition,
                                    page: 1,
                                    type: 'condition',
                                });
                            } }
                            placeholder={ t('common.Search') }/>
                    </div>
                    <div className={ `${ result.length ? 'd-flex justify-content-between align-items-baseline' : 'd-none' }` }>
                        <div className={ 'pt-3' }>
                            <BulkActionSetRoleOnMobile
                                style={{width: 148}}
                                hidden={ users.length === 0 }
                                title={ t('RecordList.BulkAction') }
                                resource-permission={ '|staffList|button-bulkAction|' }
                                onSelect={ (event) => setShowModal(event.kirokuSelected.value) }
                            />
                        </div>
                        <KirokuPagination
                            className={ 'pb-3 pt-3' }
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                            increasePage={ nextPage }
                            decreasePage={ prevPage }
                            currentPage={ pagination.currentPage }
                            totalPage={ pagination.totalPage }
                        />
                    </div>
                </ComponentMobile>
                <ComponentWeb>
                    <KirokuCardWeb>
                        <div className='d-flex justify-content-between p-3'>
                            <KirokuButton color={ 'white' } onClick={ () => setShowModal('filter') }>
                                <span className={ 'pr-2' }>{ t('Surveys.FilterBy') }</span>
                                <i className='fa fa-chevron-down text-secondary'
                                   style={ { fontSize: '10px' } }/>
                            </KirokuButton>
                            <div style={ { minWidth: 100 } }>
                                <KirokuInputSearcher
                                    value={ keySearch }
                                    onChange={ (e) => setKeySearch(e.target.value) }
                                    onSubmit={ () => {
                                        let teamIds      = teamSelected.map(team => team.id);
                                        let customerIds  = customerSelected.map(customer => customer.id);
                                        let newCondition = {
                                            ...condition,
                                            customers: customerIds,
                                            teams    : teamIds,
                                            name: keySearch,

                                        };

                                        if (!teamIds.length) {
                                            delete newCondition['teams'];
                                        }

                                        if (!customerIds.length) {
                                            delete newCondition['customers'];
                                        }
                                        if(!newCondition.name) {
                                            delete newCondition.name;
                                        }

                                        search({
                                            ...newCondition,
                                            page: 1,
                                            type: 'condition',
                                        });
                                    } }
                                    placeholder={ t('common.Search') }/>
                            </div>
                        </div>
                        <div>
                            <LabelCondition condition={ makeLabelCondition }
                                            handleRemoveCondition={ (conditionRemove) => handleRemoveCondition(conditionRemove) }/>
                        </div>
                        <div className='pt-2'>
                            <KirokuLoader loading={ loading }>
                                <KirokuNotFound isResults={ users.length }>
                                    <KirokuTable>
                                        <THeader>
                                            <tr>
                                                <Th style={ { width: 50 } }
                                                    resource-permission={ '|staffList|button-delete|' }>
                                                    <KirokuCheckBox onChange={ handleCheckAll }
                                                                    checked={ findIndex(users, user => !user.isChecked) === -1 }/>
                                                </Th>
                                                <th style={ { minWidth: 180 } }>{ t('common.Name') }</th>
                                                <th style={ { minWidth: 100 } }>{ t('common.Title') }</th>
                                                <th style={ { minWidth: 130 } }>{ t('common.Role') }</th>
                                                <th style={ { minWidth: 300 } }>{ t('common.TeamName') }</th>
                                                <th style={ { minWidth: 100 } }>{ t('common.WorkingForm') }</th>
                                                <Th resource-permission={ '|staffList|button-delete|' }
                                                    style={ { minWidth: 200 } }
                                                    className='text-center'/>
                                            </tr>
                                        </THeader>
                                        <TBody>
                                            { users.map((user, index) => {
                                                let teamName        = user.teams.map(team => team.data);
                                                let currentTeamName = teamName.join(', ');
                                                return <tr key={ user.id }>
                                                    <Td resource-permission={ '|staffList|button-delete|' }>
                                                        <KirokuCheckBox
                                                            onChange={ () => handleCheckUser(index) }
                                                            checked={ !!user.isChecked }
                                                        />
                                                    </Td>
                                                    <td>
                                                        <Link to={{
                                                            pathname:  `/users/${ user.id }`,
                                                            state : {
                                                                previousPage : 'userList'
                                                            }
                                                        }}>
                                                            <span>{ user.name }</span>
                                                        </Link>
                                                    </td>
                                                    <td>{ user.title }</td>
                                                    <td>{ t(`common.${ user.role }`) }</td>
                                                    <td> { currentTeamName } </td>
                                                    <td>{ t(`common.${ user['working_form'] }`) }</td>
                                                    <Td resource-permission={ '|staffList|button-edit|' }>
                                                        <div
                                                            className='d-flex justify-content-between p-1 fix-icon-align'>
                                                            <LinkTo
                                                                resource-permission={ '|staffList|button-edit|' }
                                                                href={ `/users/edit/${ user.id }` }
                                                                text={ <span
                                                                    className='ml-1  text-dark'>{ t('common.Edit') }</span> }
                                                                icon={ <i
                                                                    className="fas fa-pencil-alt text-secondary"/> }
                                                            />
                                                            <LinkTo
                                                                resource-permission={ '|staffList|button-duplicate|' }
                                                                href={ `/users/duplicate/${ user.id }` }
                                                                text={ <span
                                                                    className='ml-1  text-dark'>{ t('staff.duplicateStaff') }</span> }
                                                                icon={ <i className="fas fa-clone text-secondary"/> }
                                                            />
                                                            <DivPermission
                                                                resource-permission={ '|staffList|button-delete|' }>
                                                                <div style={ { cursor: 'pointer' } }
                                                                     onClick={ () => {
                                                                         setShowModal('delete');
                                                                         setDeletedUser(user);
                                                                     } }>
                                                                    <i className="far fa-trash-alt text-secondary"/>
                                                                    <span
                                                                        className='ml-1  text-dark'>{ t('common.Delete') }</span>
                                                                </div>
                                                            </DivPermission>
                                                        </div>
                                                    </Td>
                                                </tr>;
                                            }) }
                                        </TBody>
                                    </KirokuTable>
                                </KirokuNotFound>
                            </KirokuLoader>
                        </div>
                    </KirokuCardWeb>
                    <div className={ `pt-3 ${ result.length ? 'd-flex justify-content-between align-items-baseline' : 'd-none' }` }>
                        <BulkActionSetRole
                            style={{width: 148}}
                            location={ 'top' }
                            title={ t('RecordList.BulkAction') }
                            resource-permission={ '|staffList|button-bulkAction|' }
                            onSelect={ (event) => setShowModal(event.kirokuSelected.value) }
                            disabled={ findIndex(users, user => user.isChecked) === -1 }
                        />
                        <KirokuPagination
                            className={ 'pb-3' }
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                            increasePage={ nextPage }
                            decreasePage={ prevPage }
                            currentPage={ pagination.currentPage }
                            totalPage={ pagination.totalPage }
                        />
                    </div>
                </ComponentWeb>
                <ComponentMobile>
                    <div className='pt-10'>
                        <KirokuCardList
                            headerLeft={ t('common.Staff') }
                            headerRight={ <DivPermission
                                resource-permission={ '|staffList|button-edit|' }>{ t('common.Action') }</DivPermission> }
                        >
                            <KirokuLoader loading={ loading }>
                                <KirokuNotFound isResults={ users.length }>
                                    <div>
                                        { users.map(user =>
                                            <KirokuCard key={ user.id } action={
                                                <ActionPermission
                                                    resource-permission={ '|staffList|button-edit|' }
                                                    surveyId={ user.id }>
                                                    <KirokuPopoverItem>
                                                        <div
                                                            className='d-flex justify-content-between p-1 fix-icon-align'>
                                                            <Link
                                                                to={ `/users/edit/${ user.id }` }>
                                                                <i className="fas fa-pencil-alt"/><span
                                                                className='ml-1'>{ t('common.Edit') }</span>
                                                            </Link>
                                                            <LinkTo
                                                                resource-permission={ '|staffList|button-duplicate|' }
                                                                href={ `/users/duplicate/${ user.id }` }
                                                                text={ t('staff.duplicateStaff') }
                                                                icon={ <i className="fas fa-clone"/> }
                                                            />
                                                            <div style={ { cursor: 'pointer' } }
                                                                 onClick={ () => {
                                                                     setShowModal('delete');
                                                                     setDeletedUser(user);
                                                                 } }>
                                                                <i className="far fa-trash-alt"/><span
                                                                className='ml-1'>{ t('common.Delete') }</span>
                                                            </div>
                                                        </div>
                                                    </KirokuPopoverItem>
                                                </ActionPermission> }>
                                                <div>
                                                    <Link to={{
                                                        pathname:  `/users/${ user.id }`,
                                                        state : {
                                                            previousPage : 'userList'
                                                        }
                                                    }}><span>{ user.name }</span></Link>
                                                    <KirokuLabel color='light'
                                                                 style={ {
                                                                     padding   : '2px 4px',
                                                                     marginLeft: '.5rem',
                                                                 } }>{ t(`common.${ user.role }`) }
                                                    </KirokuLabel>
                                                </div>

                                                <span className='mr-2'>{ user.title }</span>
                                            </KirokuCard>) }
                                    </div>
                                </KirokuNotFound>
                            </KirokuLoader>
                        </KirokuCardList>
                    </div>
                    <div className={ `${ result.length ? 'd-flex justify-content-between align-items-baseline' : 'd-none' }` }>
                        <div className={ 'pt-3' }>
                            <BulkActionSetRoleOnMobile
                                style={{width: 148}}
                                location={ 'top' }
                                hidden={ users.length === 0 }
                                title={ t('RecordList.BulkAction') }
                                resource-permission={ '|staffList|button-bulkAction|' }
                                onSelect={ (event) => setShowModal(event.kirokuSelected.value) }
                            />
                        </div>
                        <KirokuPagination
                            className={ 'pb-3 pt-3' }
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                            increasePage={ nextPage }
                            decreasePage={ prevPage }
                            currentPage={ pagination.currentPage }
                            totalPage={ pagination.totalPage }
                        />
                    </div>
                    <div style={ { marginBottom: 80 } }/>
                </ComponentMobile>
            </div>
            <ModalSetRoleLeader show={ showModal === 'leader' }
                                closeModal={ () => setShowModal('') }
                                checkedUsers={ checkedUsers }
                                searchAfterSetRole={ () => {
                                    notification(t('message.DeleteSuccess'), 'success');
                                    search({
                                        ...condition,
                                        page: 1,
                                        type: 'condition',
                                    });
                                } }
            />
            <ModalSetRoleStaff show={ showModal === 'staff' }
                               closeModal={ () => setShowModal('') }
                               checkedUsers={ checkedUsers }
                               searchAfterSetRole={ () => {
                                   notification(t('message.DeleteSuccess'), 'success');
                                   search({
                                       ...condition,
                                       page: 1,
                                       type: 'condition',
                                   });
                               } }
            />
            <ModalConfirmDeleteUser show={ showModal === 'delete' }
                                    closeModal={ () => setShowModal('') }
                                    user={ deletedUser }
                                    searchAfterDelete={ () => {
                                        notification(t('message.DeleteSuccess'), 'success');
                                        search({
                                            ...condition,
                                            page: 1,
                                            type: 'condition',
                                        });
                                    } }
            />
            <ModalFilterUser show={ showModal === 'filter' }
                             condition={ makeLabelCondition }
                             searchAfterFilter={ (conditionFilter) => {

                                 let teamIds      = teamSelected.map(team => team.id);
                                 let customerIds  = customerSelected.map(customer => customer.id);
                                 let conditionSearch = {
                                     ...condition,
                                     name: keySearch,
                                     page  : 1,
                                     type  : 'condition',
                                     status: conditionFilter,
                                     customers: customerIds,
                                     teams    : teamIds,
                                 };

                                 if (!teamIds.length) {
                                     delete conditionSearch['teams'];
                                 }

                                 if (!customerIds.length) {
                                     delete conditionSearch['customers'];
                                 }

                                 if (!conditionFilter) {
                                     delete conditionSearch.status;
                                 }

                                 if(!conditionSearch.name) {
                                     delete conditionSearch.name;
                                 }

                                 search(conditionSearch);
                             } }
                             closeModal={ () => setShowModal('') }
            />
            <ModalSelectUserSetRoleLeader show={ showModal === 'selectedLeader' }
                                          closeModal={ () => setShowModal('') }
                                          handleSubmit={ (value) => {
                                              setUsers(value);
                                          } }
                                          users={ users }
                                          searchAfterSetRole={ () => {
                                              notification(t('message.DeleteSuccess'), 'success');
                                              search({
                                                  ...condition,
                                                  page: 1,
                                                  type: 'condition',
                                              });
                                          } }
            />
            <ModalSelectUserSetRoleStaff show={ showModal === 'selectedStaff' }
                                         closeModal={ () => setShowModal('') }
                                         handleSubmit={ (value) => {
                                             setUsers(value);
                                         } }
                                         users={ users }
                                         searchAfterSetRole={ () => {
                                             notification(t('message.DeleteSuccess'), 'success');
                                             search({
                                                 ...condition,
                                                 page: 1,
                                                 type: 'condition',
                                             });
                                         } }
            />
        </div>
    );
}
