import { useTranslation }   from 'react-i18next';
import countBy              from 'lodash/countBy';
import { getFlowQuestions } from '../HelperComponent/getQuestions';

function getChoices(questions){
    let choices = [];
    questions.forEach( item => {
        item.choices.forEach( choice => {
            if (choices.indexOf(choice.answer) < 0){
                choices.push(choice.answer);
            }
        })
    });
    return choices;
}

export default function MultiChoiceChart(records, question) {

    const { t } = useTranslation();
    records = records.map(record => getFlowQuestions(record.survey_template));
    let questions = records.reduceRight((questions, questionsNext) => questions.concat(questionsNext));
    questions = questions.filter(q => q.content === question.content && q.type === question.type && q.type === 'MultiChoice');

    const choices = getChoices(questions);
    let data      = choices.map((choice, index) => {
        return [
            choice,
            countBy(questions, function (question) {
                if (question.answers[ index ]) {
                    return question.answers[ index ].is_choice;
                }
            })[ true ] || 0,
        ];
    });
    data.unshift([t('createRecord.Choices'), t('createRecord.Selected')]);

    return {
        data,
        isNotDataAnalysis: questions.filter(question => isNotAnswer(question.answers)).length === questions.length
    };
}

function isNotAnswer(answers = []) {
    return answers.findIndex(answer => answer[ 'is_choice' ]) === -1;
}
