import omit                   from 'lodash/omit';
import { availableQuestions } from '../Records/RecordTaking/QuestionTypeWeb';

export const ANSWER_QUESTION      = 'ANSWER_QUESTION';
export const NAVIGATE_TO_QUESTION = 'NAVIGATE_TO_QUESTION';
export const CURRENT_QUESTION     = 'CURRENT_QUESTION';

function convertArrayToObject(array) {
    let obj = {};
    array.forEach(function(data){
        obj[data[0]] = data[1]
    });
    return obj;
}

function isDisabled(ranges, questionIndex) {
    return !!ranges.find(([start, end]) => {
        return questionIndex > parseInt(start) && questionIndex < end;
    });
}

function answerQuestion(action, state) {
    if (action.question === state.drafts.length - 1) {
        return {
            ...state,
            drafts: state.drafts.map(question => {
                if (question.index === action.question) {
                    return {
                        ...question,
                        answers: action.answer,
                    };
                }
                return question;
            }),
        };
    }

    if (!state.drafts[ action.next ]) {
        throw new Error(`Question [${action.next}] is not existed`);
    }

    const entries = Object.entries(state.disabledRanges).filter(([start]) => {
        return ((start <= action.question) || (start >= action.next));
    });

    let entriesObject;
    if(typeof Object.fromEntries === 'function') {
        entriesObject = Object.fromEntries(entries)
    } else {
        entriesObject = convertArrayToObject(entries)
    }

    const disabledRanges = action.next > action.question + 1 ?
        { ...entriesObject, [ action.question ]: action.next } :
        omit(entriesObject, action.question);

    const drafts = state.drafts.map(question => {
        if (question.index === action.question) {
            return {
                ...question,
                isDisabled: isDisabled(Object.entries(disabledRanges), question.index),
                answers   : action.answer,
            };
        }
        return {
            ...question,
            isDisabled: isDisabled(Object.entries(disabledRanges), question.index),
        };
    });
    return {
        ...state,
        disabledRanges,
        drafts
    }

}

function hasNextQuestion(drafts, indexNextQuestion) {
    return drafts[ indexNextQuestion ] && availableQuestions[ drafts[ indexNextQuestion ].type ].isAnswers(drafts[ indexNextQuestion])
}

export default function RecordingReducer(state, action) {
    switch (action.type) {
        case CURRENT_QUESTION: {
            return {
                ...state,
                currentQuestion: action.question,
                isEnding       : action.question.index === state.drafts.length - 1,
            };
        }

        case NAVIGATE_TO_QUESTION: {
            if (action.nextQuestion === state.drafts.length - 1) {
                return {
                    ...state,
                    currentQuestion: state.drafts[ action.nextQuestion ],
                    isEnding       : action.nextQuestion === state.drafts.length - 1,
                };
            }

            if (!state.drafts[ action.nextQuestion ]) {
                throw new Error(`Question [${action.next}] is not existed`);
            }

            return {
                ...state,
                currentQuestion: state.drafts[ action.nextQuestion ],
                isEnding       : false,
            };
        }
        case ANSWER_QUESTION: {
            const currentState = answerQuestion(action, state);
            const drafts = currentState.drafts;
            if (hasNextQuestion(drafts, action.next)) {
                const propsAction = {
                    question: drafts[ action.next ].index,
                    answer  : drafts[ action.next ].answers,
                    next    : availableQuestions[ drafts[ action.next ].type ].getNext(drafts[ action.next ])
                }
                let stateAnswer = []
                for(let i = action.question; i < state.drafts.length; i++) {
                    stateAnswer.push(answerQuestion(propsAction, currentState))
                }
                return stateAnswer[stateAnswer.length-1];
            }
            return currentState;
        }
        default :
            return state;
    }
}
