import React                                from 'react';
import { useTranslation }                   from 'react-i18next';
import findIndex                            from 'lodash/findIndex';
import { KirokuDropdownResetLabelSelected } from '../../ui';

export default function ActionDropdown({ search, status }) {
    const { t } = useTranslation();

    const itemOptions = [
        {
            value    : '',
            component: (
                <div className={`${!status ? 'active' : ''}`}>
                    <img src={`/assets/img/All.svg`} alt="All.svg"/> &nbsp;
                    <span>{t('common.All')}</span>
                </div>
            ),
            label    : t('common.All'),
        },
        {
            value    : 'update',
            component: (
                <div className={`${status === 'update' ? 'active' : ''}`}>
                    <img src={`/assets/img/file-update.svg`} alt="file-update.svg"/> &nbsp;
                    <span>{t('common.update')}</span>
                </div>
            ),
            label    : t('common.update'),
        },
        {
            value    : 'create',
            component: (
                <div className={`${status === 'create' ? 'active' : ''}`}>
                    <img src={`/assets/img/file-add.svg`} alt="file-add.svg"/>&nbsp;&nbsp;
                    <span>{t('common.create')}</span>
                </div>
            ),
            label    : t('common.create'),
        },
        {
            value    : 'delete',
            component: (
                <div className={`${status === 'delete' ? 'active' : ''}`}>
                    <img src={`/assets/img/file-delete.svg`} alt="file-delete.svg"/>&nbsp;&nbsp;
                    <span>{t('common.delete')}</span>
                </div>
            ),
            label    : t('common.delete'),
        },
        {
            value    : 'view',
            component: (
                <div className={`${status === 'view' ? 'active' : ''}`}>
                    <img src={`/assets/img/file-view.svg`} alt="file-view.svg"/>&nbsp;&nbsp;
                    <span>{t('common.view')}</span>
                </div>
            ),
            label    : t('common.view'),
        },{
            value    : 'comment',
            component: (
                <div className={`${status === 'comment' ? 'active' : ''}`}>
                    <img src={`/assets/img/file-text.svg`} alt="file-text.svg"/>&nbsp;&nbsp;
                    <span>{t('common.comment')}</span>
                </div>
            ),
            label    : t('common.comment'),
        }
    ];

    const getTitle = (conditionAction) => {
        const action     = conditionAction ? conditionAction : '';
        const indexTitle = findIndex(itemOptions, (item) => item.value === action);
        return itemOptions[ indexTitle ].component;
    };


    return (
        <KirokuDropdownResetLabelSelected
            title={getTitle(status)}
            style={{ minWidth: 206 }}
            items={itemOptions}
            onSelect={event => {
                search({ action: event.kirokuSelected.value, page: 1 });
            }}
        />
    );
}
