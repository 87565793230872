import React                     from 'react';
import useCertification          from './useCertification';
import { useTranslation }        from 'react-i18next';
import { DateDiff, GeneralDiff } from '../DifferenceType';
import moment                    from 'moment';
import findIndex                 from 'lodash/findIndex';

export default function Certification({ old, log }) {
    const { t } = useTranslation();
    const {
              certification_number, issuer, diffServices,
              diffMonitoringTerms,
          }     = useCertification(old.data, log.data);

    return <div className="p-2">
        <div className={'font-size-16 pb-10px'}> {t('customerDifference.certification')}</div>
        <div hidden={certification_number.lhs === certification_number.rhs}>
            <div className="d-flex align-items-center">
                <b>{t('CustomerEditor.CertificationNumber')}</b>
                <GeneralDiff lhs={certification_number.lhs} rhs={certification_number.rhs}/>
            </div>
        </div>

        <div hidden={issuer.lhs === issuer.rhs}>
            <div className="d-flex align-items-center">
                <b>{t('CustomerEditor.CertificationIssuer')}</b>
                <GeneralDiff lhs={issuer.lhs} rhs={issuer.rhs}/>
            </div>
        </div>

        <div
            hidden={issuer.lhs !== issuer.rhs ||
            certification_number.lhs !== certification_number.rhs ||
            diffServices.length || diffMonitoringTerms.length}
        >
            <div className={'text-center'}>
                <div style={{ fontSize: 24 }} className={'pb-10px'}>{t('customerDifference.notChange')}</div>
            </div>
        </div>

        <h6 >{t('CustomerEditor.Service')}</h6>
        <div
            className={'pb-2 text-align-center'}
            style={{ fontSize: 24 }}
            hidden={findIndex(diffServices, service => service.isDiff) !== -1}
        >
            {t('customerDifference.notChange')}
        </div>

        {diffServices.map((diffService, index) => {
            return <React.Fragment key={index}>
                <div hidden={!diffService.isDiff}>
                    <div className={'d-flex align-items-center'}>
                        <b>{t('customerDifference.name')}: </b> &nbsp;
                        <GeneralDiff lhs={diffService.lhs && diffService.lhs.name}
                                     rhs={diffService.rhs && diffService.rhs.name}/>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <b>{t('CustomerEditor.Start')}: </b> &nbsp;
                        <DateDiff lhs={diffService.lhs && diffService.lhs.startDate}
                                  rhs={diffService.rhs && diffService.rhs.startDate}/>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <b>{t('CustomerEditor.End')}: </b> &nbsp;
                        <DateDiff lhs={diffService.lhs && diffService.lhs.endDate}
                                  rhs={diffService.rhs && diffService.rhs.endDate}/>
                    </div>
                </div>
            </React.Fragment>;
        })}

        <div className={'font-size-16 pb-10px'}>{t('CustomerEditor.monitoringTerms')}</div>
        <div
            className={'pb-2 text-align-center'}
            style={{ fontSize: 24 }}
            hidden={findIndex(diffMonitoringTerms, service => service.isDiff) !== -1}
        >
            {t('customerDifference.notChange')}
        </div>
        {diffMonitoringTerms.map((diffMonitoring, index) => {
            const lhsDate = diffMonitoring.lhs && diffMonitoring.lhs.date;
            const rhsDate = diffMonitoring.rhs && diffMonitoring.rhs.date;
            return <React.Fragment key={index}>
                <div hidden={!diffMonitoring.isDiff}>
                    <div className="d-flex align-items-center">
                        <p className={'text-decoration margin-none'}>
                            {lhsDate ? moment.unix(lhsDate).format(t('dateTimeFormat.YearMonth')) : ''}
                        </p>
                        <i className="fas fa-arrow-right pl-2 pr-2"/>
                        <p className="margin-none">
                            {rhsDate ? moment.unix(rhsDate).format(t('dateTimeFormat.YearMonth')) : ''}
                        </p>
                    </div>
                </div>
            </React.Fragment>;
        })}
    </div>;
}
