import { resources }                from '@kiroku/api';
import { useCallback }              from 'react';
import { useTemplatesModalContext } from './TemplateModalContextProvider';
import findIndex                    from 'lodash/findIndex';
import {
    CHANGE_SELECTED_TAB_NAME, CHECKED_TEMPLATES, HANDLE_CHANGE_KEYWORD, HANDLE_CHANGE_KEYWORD_SELECTED,
    HANDLE_CHECK_ALL_TEMPLATES, HANDLE_CHECK_ALL_TEMPLATES_SELECTED, HANDLE_CHECK_TEMPLATE_SELECTED,
    HANDLE_RESET_CONDITIONS, SET_TEMPLATE_MODAL_TAB, SET_TEMPLATES,
}                                   from './ModalTemplateReducer';
import { useRecordContext }         from '../../ConditionBuilder/RecordContext';

export default function useSelectTemplateModal() {
    const { surveyState, dispatch } = useTemplatesModalContext();

    const {
              templateShowInModal, templates,
              templateSelectedShowInModal,
              selectedTabName, keywords, keywordSelected
          } = surveyState;

    const { search, loading } = resources.useSearch('Template');

    const { recordCondition } = useRecordContext();

    const getTemplateListOptions = useCallback(() => {
        search({
            type: 'all',
        }).then(res => {
            let surveys = res.result;
            if (recordCondition.tId === 'all') {
                let checkedAll = surveys.map(survey => {
                    return {
                        ...survey,
                        checked: true
                    }
                });
                dispatch({
                    type     : SET_TEMPLATES,
                    templates: checkedAll,
                });
            } else if (recordCondition.tId.length) {
                recordCondition.tId.forEach(survey => {
                    let indexSurvey      = findIndex(surveys, rsl => rsl.id === survey);
                    surveys[indexSurvey] = {
                        ...surveys[indexSurvey],
                        checked: true,
                    };
                });

                dispatch({
                    type     : SET_TEMPLATES,
                    templates: surveys,
                });
            } else {
                dispatch({
                    type     : SET_TEMPLATES,
                    templates: surveys,
                });
            }
            dispatch({
                type : SET_TEMPLATE_MODAL_TAB
            })
        });
    }, [dispatch, search, recordCondition.tId]);

    const handleChecked = useCallback((index) => {
        dispatch({
            type: CHECKED_TEMPLATES,
            index,
        });
    }, [dispatch]);

    const handleCheckAllTemplates = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_TEMPLATES,
        });
    }, [dispatch]);

    const handleChangeKeyword = useCallback((keywords) => {
        dispatch({
            type: HANDLE_CHANGE_KEYWORD,
            keywords,
        });
    }, [dispatch]);

    const handleChangeKeywordSelected = useCallback((keywordSelected) => {
        dispatch({
            type: HANDLE_CHANGE_KEYWORD_SELECTED,
            keywordSelected,
        });
    }, [dispatch]);

    const setShowOpenModal = useCallback((selectedTabName) => {
        dispatch({
            type: CHANGE_SELECTED_TAB_NAME,
            selectedTabName,
        });
    }, [dispatch]);

    const handleCheckAllSelected = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_TEMPLATES_SELECTED,
        });
    }, [dispatch]);

    const handleCheckTemplateSelected = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_TEMPLATE_SELECTED,
            index,
        });
    }, [dispatch]);

    const resetInitCondition = useCallback(() => {
        dispatch({
            type: HANDLE_RESET_CONDITIONS,
        });
    }, [dispatch]);

    return {
        loading,
        templates,
        handleChecked,
        selectedTabName,
        setShowOpenModal,
        templateShowInModal,
        handleChangeKeyword,
        getTemplateListOptions,
        handleCheckAllTemplates,
        handleChangeKeywordSelected,
        templateSelectedShowInModal,
        handleCheckAllSelected,
        handleCheckTemplateSelected,
        resetInitCondition,
        keywords,
        keywordSelected
    };
}
