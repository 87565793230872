import React                                                 from 'react';
import { KirokuCheckBox, KirokuCheckboxBorder, KirokuInput } from '../../../../../ui';
import { useTranslation }                                    from 'react-i18next';
import DropdownSelectTeam                                    from '../../SelectUpdater/DropdownSelectTeam';
import useSelectedCustomer                                   from '../useSelectedCustomer';
import findIndex                                             from 'lodash/findIndex';
import KirokuLoader                                          from '../../../../../ui/KirokuLoader';

export default function OptionsCustomerMobile({loading, teams, show}) {
    const { t } = useTranslation();
    const {
              handleChangeTags, handleCheckAllCustomers,
              handleSearchKeywords, customersShowInModal,
              handleCheckCustomer, handleResetCondition,
              keywords,
          }     = useSelectedCustomer();
    return (
        <div className="pt-2 pl-1 pr-1">
            <div className='font-text-on-btn'>
                <DropdownSelectTeam handleChangeTags={ (value) => handleChangeTags(value) }
                                    teamSearch={teams} show={ show }
                                    placeholder={ t('RecordsBeta.refineByTeam') }
                />
            </div>
            <div className=" pb-3 font-text-on-btn">
                <KirokuInput value={ keywords }
                             className={ 'css-input-medium' }
                             onChange={ (e) => handleSearchKeywords(e.target.value) }
                             placeholder={ t('RecordsBeta.filterByCustomerName') }/>
            </div>
            <div className={'card'}>
                <KirokuLoader loading={ loading }>
                    { customersShowInModal.length ?
                        <div>
                            <div className={ 'd-flex p-3 border-bottom justify-content-between' }
                                 style={ { border: '1px solid rgb(223, 226, 229)' } }>
                                <KirokuCheckBox
                                    checked={ findIndex(customersShowInModal, (ctm) => (!ctm.selected)) === -1 }
                                    onChange={ handleCheckAllCustomers }
                                />
                                <div/>
                                <div className={ 'font-text-reset font-size-16' } style={ { color: '#007BFF' } }
                                     onClick={ handleResetCondition }
                                >
                                    { t('RecordsBeta.resetSelection') }
                                </div>
                            </div>
                            { customersShowInModal.map((customer, index) => (
                                <div key={ index }
                                     onClick={ () => handleCheckCustomer(index) }>
                                    <KirokuCheckboxBorder checked={ !!customer.selected }
                                                    id={ `${ customer.id }-${ index }` }
                                                    onChange={ () => handleCheckCustomer(index) }
                                                    content={ <span
                                                        className={ 'font-text-in-checkbox' }> { customer.name }</span> }
                                    />
                                </div>
                            ))
                            }
                        </div> : <div className='d-flex' style={ { height: '50vh' } }>
                            <div className={ 'font-text-not-found pt-3' }
                                 style={ { margin: 'auto' } }>
                                { t('RecordsBeta.noCustomers') }
                            </div>
                        </div> }
                </KirokuLoader>
            </div>
        </div>
    );
}
