import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import {
    KirokuButton, KirokuCheckboxBorder, KirokuInputSearch, KirokuModal, KirokuModalBody, KirokuModalFooter,
    KirokuModalHeader, SearchNotFound,
}                                     from '../../../ui';
import findIndex                      from 'lodash/findIndex';
import uniqBy                         from 'lodash/uniqBy';

export default function ModalRemoveMembers(props) {
    const { t }                                     = useTranslation();
    const { membersChoice }                         = props;
    const [keyword, setKeyword]                     = useState('');
    const [members, setMembers]                     = useState(membersChoice);
    const [membersListInTeam, setMembersListInTeam] = useState([]);

    const { show } = props;

    useEffect(() => {
        if (membersChoice.length) {
            setMembers(membersChoice);
            setMembersListInTeam(membersChoice);
        } else {
            setMembers([]);
            setMembersListInTeam([]);
        }
    }, [membersChoice]);

    const handleSearchInput = (event) => {
        let value    = event.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        const result = membersListInTeam.filter(member => member.name.toLowerCase().search(value.toLowerCase()) !== -1);
        setMembers(result);
    };

    useEffect(() => {
        if (!show) {
            setKeyword('');
            setMembers(membersChoice);
            setMembersListInTeam(membersChoice);
        }
    }, [show, membersChoice]);

    const handleCheckAll = () => {
        const isCheck = findIndex(members, member => !member.isChecked) === -1;
        let newMembersSelected = members.map(member => {
            return {
                ...member,
                isChecked: !isCheck,
            };
        });

        setMembers(newMembersSelected);

        setMembersListInTeam(membersListInTeam.map(member => {
            const indexMemberIdSelected = findIndex(newMembersSelected, (mber) => mber.id === member.id);
            if(indexMemberIdSelected !== -1) {
                return {
                    ...member,
                    isChecked: !member.isChecked
                }
            }
            return {
                ...member,
            }
        }));
    };

    const handleCheckMember = (idMembers) => {
        setMembers(members.map((member) => {
            if (idMembers === member.id) {
                return {
                    ...member,
                    isChecked: !member.isChecked,
                };
            }
            return member;
        }));
        setMembersListInTeam(membersListInTeam.map((member) => {
            if (idMembers === member.id) {
                return {
                    ...member,
                    isChecked: !member.isChecked,
                };
            }
            return member;
        }));
    };


    return (
        <KirokuModal show={props.show}>
            <KirokuModalHeader>
                <div className="font-header-on-modal">{t('Team.DeleteMember')}</div>
            </KirokuModalHeader>
            <KirokuModalBody className={'pt-3 pl-3 pr-3'}>
                <div style={ { paddingBottom: 17 } }>
                    <KirokuInputSearch
                        onChange={ (event) => {
                            handleSearchInput(event);
                            setKeyword(event.target.value);
                        }}
                        value={ keyword }
                        style={ {height: 36, fontSize: 14}}
                        placeholder={ t('Team.placeholderStaffName') }
                    />
                </div>
                <div id="body-modal-as">
                    {members.length ?
                        <div className={ 'checkbox-bigger p-3 d-flex justify-content-between data-in-modal' }>
                            <div className={'font-size-16'}>{ t('common.Members') }</div>
                            <div className='text-primary' style={{ cursor: 'pointer' }}>
                                    <span hidden={findIndex(members, member => !member.isChecked) === -1}
                                          onClick={handleCheckAll}
                                          className={'font-size-16'}
                                    >{t('common.SelectAll')}</span>
                                <span hidden={findIndex(members, member => !member.isChecked) !== -1}
                                      onClick={handleCheckAll}
                                      className={'font-size-16'}
                                >{t('common.UnSelect')}</span>
                            </div>
                        </div>: <div/>
                    }
                    <div className="background-color" style={ {
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 450px)',
                        maxHeight: 'calc(100vh - 450px)',
                        height   : 'auto',
                    } }>
                    { !members.length ? <SearchNotFound/> : members.map((member, index) => (
                        <div key={ index }
                            className={'checkbox-bigger cursor-pointer'}
                             onClick={ (event) => {
                                 event.preventDefault();
                                 handleCheckMember(member.id);
                             }}
                        >
                            <KirokuCheckboxBorder
                                onChange={ () => handleCheckMember(member.id) }
                                checked={ !!member.isChecked }
                                content={<span className={ 'font-text-in-checkbox cursor-pointer' }>{ member.name }</span>}
                            />
                        </div>
                    )) }
                    </div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={props.hide} color={'white'}>
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton
                    onClick={() => {
                        let memberRemoved = membersListInTeam.filter(member => !member.isChecked);
                        let users         = uniqBy(memberRemoved, 'id');
                        let userToSubmit  = users.map(user => ({
                            ...user,
                            tagId     : user.id,
                            tagContent: { ...user },
                        }));
                        props.hide();
                        props.getMemberRemoved(userToSubmit);
                    }}
                    color={'danger'}>
                    {t('common.Delete')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
